import { Box, IconButton, InputAdornment, styled, TextField, Typography } from '@mui/material'
import { useSizeWindow } from 'Helpers/GlobalHelpers'
import { observer } from 'mobx-react-lite'
import { DefaultLabelTypo, DefaultInputStyled } from './InputsStyled'
import { useState } from 'react'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { ReactComponent as PasswordOffIcon } from './Assets/password-off.svg'
import { ReactComponent as PasswordOnIcon } from './Assets/password-on.svg'
import { ReactComponent as SearchIcon } from './Assets/SearchIcon.svg'
import { isObject } from 'lodash'
import ModalPassword from './ModalPassword/ModalPassword'
import { storeElement } from 'GlobalStores/StoreElement'

const DefaultInput = observer(props => {
    const { width } = useSizeWindow()
    const [showPassword, setShowPassword] = useState(false)
    const {
        //filled-error-helper-text  id should be unique
        label = '',
        value = '',
        fullWidth = true,
        onChange,
        onBlur = () => {},
        type = 'text',
        error = true,
        helpertext = '',
        paddingleft = 16,
        paddingright = 12,
        helpertextcardtype,
        typeKey,
        onKeyUp,
        placeholder = '',
        endAdornmentToggle = () => {},
        showAdornmentLabel = null,
        item = {}
    } = props

    const handleTogglePasswordVisibility = () => {
        setShowPassword(!showPassword)
    }

    const handleToggleZipSearch = () => {
        endAdornmentToggle(true)
    }
    // SearchIcon

    const getEndAdornment = (type = null) => {
        let endAdornment = <></>
        switch (type) {
            case 'password':
                endAdornment = (
                    <InputAdornment position="end">
                        <IconButton onClick={handleTogglePasswordVisibility} edge="end">
                            {!showPassword ? <PasswordOffIcon /> : <PasswordOnIcon />}
                        </IconButton>
                    </InputAdornment>
                )
                break
            case 'zipSearch':
                endAdornment = (
                    <InputAdornment position="end">
                        <IconButton onClick={handleToggleZipSearch} edge="end">
                            <SearchIcon width={'18px'} height={'18px'} />
                        </IconButton>
                    </InputAdornment>
                )
                break
            default:
                endAdornment = null
                break
        }

        return endAdornment
    }

    const getRealType = (type = 'text') => {
        let realType = type
        switch (type) {
            case 'password':
                if (showPassword) {
                    realType = 'text'
                }
                break
            case 'zipSearch':
                realType = 'tel'
                break
            default:
                realType = type
                break
        }
        return realType
    }

    return (
        <Box width={fullWidth ? '100%' : 'auto'}>
            {label !== null && (
                <div 
                    style={{ 
                        display: showAdornmentLabel && 'flex', 
                        justifyContent: showAdornmentLabel && storeElement.dynamicWidth > 768 && 'space-between', 
                        marginBottom: '8px', 
                        paddingLeft: paddingleft,
                        paddingRight: showAdornmentLabel && paddingright
                    }}
                >
                    <label>
                        {isObject(label) ? (
                            label
                        ) : (
                            <DefaultLabelTypo variant="label" style={{ color: 'rgba(21, 56, 98, 0.5)' }}>
                                {label}
                            </DefaultLabelTypo>
                        )}
                        {typeKey === 'card_number' && helpertextcardtype && <br style={{ display: width < 883 ? 'block' : 'none' }} />}
                        <Typography variant="label" sx={{ color: '#D03C3C', marginLeft: '15px' }}>
                            {helpertextcardtype}
                        </Typography>
                    </label>
                    {showAdornmentLabel && <ModalPassword paragraph={item.info && item.info} />}
                </div>
            )}
            {props.InputProps ? (
                <TextField {...props} />
            ) : (
                <DefaultInputStyled
                    {...props}
                    type={getRealType(type)}
                    error={error}
                    label={
                        <Typography variant="label" sx={{ color: 'rgba(21, 56, 98, 0.5)' }}>
                            {label}
                        </Typography>
                    }
                    value={value}
                    helpertext={helpertext}
                    variant="outlined"
                    fullWidth={fullWidth}
                    onKeyUp={onKeyUp}
                    onChange={onChange}
                    onBlur={onBlur}
                    inputlabelprops={{
                        shrink: true
                    }}
                    inputProps={{
                        'aria-autocomplete': 'none',
                        autoComplete: 'new-password',
                        spellCheck: 'false'
                    }}
                    placeholder={placeholder}
                    sx={
                        ['password', 'zipSearch'].includes(type) && {
                            '.MuiInputAdornment-root': {
                                position: 'absolute',
                                right: '18px',
                                '.MuiSvgIcon-root': {
                                    width: '18px',
                                    height: '18px'
                                }
                            },
                            '.MuiInputBase-input': {
                                '::-ms-reveal': {
                                    display: 'none'
                                }
                            }
                        }
                    }
                    endAdornment={getEndAdornment(type) || null}
                />
            )}
            {helpertext && (
                <div style={{ marginTop: '8px', paddingLeft: paddingleft }}>
                    <label>
                        <TextHeleperTypo variant="label">{helpertext}</TextHeleperTypo>
                    </label>
                </div>
            )}
        </Box>
    )
})

// sx={
//     !!helpertext && {
//         '& .MuiInputBase-input': {
//             border: '1px solid #D03C3C;'
//         }
//     }
// }l

const TextHeleperTypo = styled(Typography)(({ theme }) => ({
    fontFamily: 'Inter',
    fontStyle: 12,
    color: '#D03C3C',
    fontWeight: 500
}))

export default DefaultInput
