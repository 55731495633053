import axios from 'axios'
import { ServerEnv } from 'Configs/ServerEnv'
import StorePaymentStep from 'GlobalStores/Cores/Elements/StorePaymentStep'
import { ObjectHelpers } from 'Helpers/ObjectHelpers'
import { get, isEmpty } from 'lodash'
import { makeAutoObservable, runInAction, toJS } from 'mobx'
import { FeelGreatAPI } from 'Services/FeelGreat/APIs'
import Payment from '../Payment'
import States from 'Components/Elements/Forms/StatesModal/Country/JordanState.json'
import StoreCheckout from '../StoreCheckout'
import StoreProducts from 'GlobalStores/StoreProducts'
import storeCheckout from '../StoreCheckout'
import Address from 'Components/Elements/Forms/StatesModal/Country/JordanAddress.json'
import httpHelper from 'Services/HttpHelper'

class Jordan {
    autoAddressDisplayKeys = ['street', 'locality', 'postal_code']
    autoAddressKeyToForm = {
        street: 'street_address',
        locality: 'city',
        administrative_area: 'state',
        postal_code: 'zip_code'
    }

    chainAddress = {
        isChainAddress: true,
        groupAddress: ['city_selection', 'district_selection']
    }

    uuid = ''
    selectedShippingPaymentOption = null

    toFormat = {
        enroll: {
            uuid: this.GetUuid(),
            city: '',
            zip: '',
            email: '',
            phone: '',
            password: '',
            shipment_options: 'delivery'
        },
        enrollWithReferral: {
            uuid: this.GetUuid(),
            city: '',
            zip: '',
            email: '',
            phone: '',
            password1: '',
            password2: '',
            shipment_options: 'delivery'
        },
        shop: {
            uuid: this.GetUuid(),
            city: '',
            zip: '',
            email: '',
            shipment_options: 'delivery'
        }
    }

    formShop = {
        fullName: '',
        email: '',
        phone: '',
        street_address: '',
        city: '',
        state: '',
        zip_code: ''
    }

    createAccountWithShippingAddress = {
        type: 'enroll',
        referral_id: '',
        firstname_en: '',
        lastname_en: '',
        email: '',
        phone: '',
        password: '',
        city: '',
        zip: '',
        period: '',
        shipment_options: 'delivery'
    }

    ExcludedFieldsShippingIfCreateAccount = ['fullName']

    ExcludedFieldsShipping = []

    mapFormKeyToFormatKey = {
        street_address: 'address',
        fullName: 'fullname',
        zip_code: 'zip',
        firstname: 'firstname_en',
        lastname: 'lastname_en',
        city_selection: 'city',
        district_selection: 'district'
    }

    ArrangeCreateCheckoutForm = [
        'firstname',
        'lastname',
        'email',
        'password',
        'phone',
        'country',
        'street1',
        'street2',
        'city_selection',
        'district_selection',
        // 'city',
        // 'district',
        'zip_code'
    ]

    PaymentStep = {
        payment: 0,
        createOrder: 0,
        autoship: -2,
        updateLog: 0
    }

    breadcrumbOption = [
        {
            caption: 'customise',
            link: '/products',
            paramsRequire: false
        },
        {
            caption: 'signup_shipping',
            link: '/checkout/create-account',
            paramsRequire: true,
            authCaption: 'shipping',
            authLink: '/checkout/shipping'
        },
        {
            caption: 'purchase',
            link: '/checkout/payment',
            paramsRequire: true
        }
    ]

    shippingMethods = [
        {
            id: 1,
            api_value: 'delivery',
            title: 'delivery',
            dict_key: 'delivery',
            for_status: [],
            options: [
                {
                    id: 1,
                    title: 'creditcard',
                    dict_key: 'creditcard',
                    for_status: []
                }
                // {
                //     id: 2,
                //     title: 'cash_on_delivery',
                //     dict_key: 'cash_on_delivery',
                //     for_status: ['M']
                // }
            ]
        },
        {
            id: 2,
            api_value: 'pickup',
            title: 'pickup_jordan_office',
            dict_key: 'pickup_jordan_office',
            for_status: [],
            options: [
                {
                    id: 1,
                    title: 'Credit card',
                    dict_key: 'creditcard',
                    for_status: []
                }
            ]
        }
    ]

    PaymentGateway = 'hydra3ds'

    orderSummaryConfig = {
        subTotal: {
            display: true,
            dict_key: 'subtotal'
        },
        shipping: {
            display: true,
            dict_key: 'shipping'
        },
        productCredit: {
            display: true,
            dict_key: 'product_credit',
            icon: true,
            allowToUse: []
        },
        vat: {
            display: false,
            dict_key: 'vat',
            value: 0.2 // 20%
        },
        shippingWithoutMethod: {
            display: true,
            displayStep: ['payment'],
            dict_key: 'shipping'
        }
    }

    landingLayouts = [
        {
            nameComponent: 'banner',
            titleContent: 'mainBanner',
            style: {}
        },
        {
            nameComponent: 'ourApproach',
            titleContent: 'ourApproach',
            style: {
                minHeight: '0px'
            }
        },
        {
            nameComponent: 'theProducts',
            titleContent: 'theProducts',
            style: {
                backgroundColor: '#F1F6FB'
            }
        },
        {
            nameComponent: 'thePractice',
            titleContent: 'thePractice',
            style: {}
        },
        {
            nameComponent: 'howItWorks',
            titleContent: 'howItWorks',
            style: {
                backgroundColor: '#FBF7F1'
            }
        },
        {
            nameComponent: 'whatExpertsSay',
            titleContent: 'expertsSay',
            style: {}
        },
        {
            nameComponent: 'realResults',
            titleContent: 'realResults',
            style: {
                backgroundColor: '#F2F9F8'
            }
        }
    ]

    productLayouts = [
        {
            nameComponent: 'productDetailSelection',
            titleContent: 'productDetailSelection',
            style: {}
        },
        {
            nameComponent: 'theProducts',
            titleContent: 'theProducts',
            style: {}
        },
        {
            nameComponent: 'frequentlyAskedQuestions',
            titleContent: 'questions',
            style: {
                backgroundColor: '#F6F9FC'
            }
        }
    ]

    StatesList() {
        let items = []
        States.map(raw => {
            items.push({
                text: raw.abbreviation,
                value: raw.abbreviation,
                html: `<span>${raw.name}</span>`
            })
        })
        return items
    }

    AddressOptionList(key = '', fn) {
        let items = { city: [], district: [] }

        const cityValue = get(
            (fn.GetCheckoutForms() || []).find(item => item.key === 'city_selection'),
            'value'
        )

        // city data
        const city = Object.keys(Address)

        city.map(raw => {
            items = {
                ...items,
                city: [
                    ...items.city,
                    {
                        text: raw,
                        value: raw,
                        html: `<span>${raw}</span>`
                    }
                ]
            }
        })

        if (cityValue) {
            // check
            Object.keys(get(Address, cityValue, {})).map(raw => {
                items = {
                    ...items,
                    district: [
                        ...items.district,
                        {
                            text: raw,
                            value: raw,
                            html: `<span>${raw}</span>`
                        }
                    ]
                }
            })
        } else {
            items = {
                ...items,
                district: []
            }
        }

        return items[key.replaceAll('_selection', '')]
    }

    MapAutoAddressToForm(form, autoAddressData) {
        const swappedValuesAsKeys = ObjectHelpers.swapKeysAndValues(this.autoAddressKeyToForm)
        return form.map(item => {
            if (swappedValuesAsKeys[item.key]) {
                if (autoAddressData.data) {
                    item.value = autoAddressData.data[swappedValuesAsKeys[item.key]]
                }
            }

            return item
        })
    }

    FormToFormat(type, form) {
        form.map(item => {
            if (this.mapFormKeyToFormatKey[item.key]) {
                if (this.toFormat[type]) {
                    runInAction(() => {
                        this.toFormat[type][this.mapFormKeyToFormatKey[item.key]] = item.value
                    })
                }
            } else {
                if (this.toFormat[type]) {
                    runInAction(() => {
                        this.toFormat[type][item.key] = item.value
                    })
                }
            }

            return item
        })
    }

    SetUuid(value) {
        this.uuid = value
    }

    GetUuid() {
        return this.uuid
    }

    GetFormat(type) {
        return this.toFormat[type]
    }

    GetPaymentStep() {
        return this.PaymentStep
    }

    GetBreadcrumbOption() {
        return this.breadcrumbOption
    }

    GetShippingMethod() {
        return this.shippingMethods
    }

    GetPageConfig() {
        return this.pageConfig
    }

    SetPaymentStep(key, value) {
        this.PaymentStep[key] = value
    }

    GetOrderSummaryConfig() {
        return this.orderSummaryConfig
    }

    SetSelectedShippingPaymentOption(value) {
        this.selectedShippingPaymentOption = value
    }

    GetSelectedShippingPaymentOption() {
        return this.selectedShippingPaymentOption
    }

    GetLandingLayout() {
        return this.landingLayouts
    }

    GetProductLayout() {
        return this.productLayouts
    }

    /**
     *
     * @param {*} uuiSubmitPaymentd
     * @param {object} detailPage { products, detailPage, isSubscribe, isEnroll, type}
     * @param {*} CreditCardEncrypted
     */
    async SubmitPayment(uuid, detailPage, CreditCardEncrypted, products, options) {
        try {
            sessionStorage.setItem('uuid', uuid)
            sessionStorage.setItem('detailPage', JSON.stringify(detailPage))
            const Pay = new Payment(uuid, detailPage)
            const responsePreparePayment = await Pay.PreparePaymentData()

            // const allowCreditEnable = StoreAuth.IsAllowCredit()

            if (!Pay.hydra.transactions) {
                Pay.hydra.transactions = {}
            }

            Pay.hydra.transactions = {
                items: [
                    {
                        amount: 'this.terms.total',
                        type: 'IOU',
                        method: 'CreditCard',
                        billToEmail: responsePreparePayment.data.data.hydra.shipToEmail,
                        billToPhone: responsePreparePayment.data.data.hydra.shipToPhone,
                        methodDetails: {
                            payer: responsePreparePayment.data.data.hydra.shipToName.firstName,
                            bankName: 'MepsPay'
                        }
                    }
                ]
            }

            await Pay.PostToHydra()

            await Pay.UpdateMepsLog('waiting')

            return {
                success: true,
                payment_id: responsePreparePayment.data.data.payment_id,
                orderData: Pay.orderData
            }
        } catch (error) {
            console.log(error)
            runInAction(() => {
                StorePaymentStep.PaymentDone = true
            })

            return { success: false }
        }
    }

    async CreateAccount(uuid, accForm, shippingForm, referral, cartItems) {
        try {
            const forms = Object.assign(this.createAccountWithShippingAddress, accForm)
            const returnShippingAddress = {}
            shippingForm.map(item => {
                if (this.ExcludedFieldsShippingIfCreateAccount.includes(item.key)) {
                    return false
                }
                if (this.mapFormKeyToFormatKey[item.key]) {
                    forms[this.mapFormKeyToFormatKey[item.key]] = item.value
                    returnShippingAddress[this.mapFormKeyToFormatKey[item.key]] = item.value
                } else {
                    forms[item.key] = item.value
                    returnShippingAddress[item.key] = item.value
                }
            })

            if (referral) {
                forms.referral_id = referral
            } else {
                delete forms.referral_id
            }

            delete forms.country

            if (!StoreCheckout.shippingMethod) {
                return {
                    success: false,
                    shippingMethodIsRequired: true
                }
            }

            const periodResponse = await FeelGreatAPI.GetPeriod('JO')
            forms.period = periodResponse.data.entryPeriod
            forms.uuid = uuid
            forms.shipment_packs = JSON.stringify(cartItems)
            if (StoreCheckout.shippingMethod) {
                forms.shipment_options = StoreCheckout.shippingMethod.api_value
            }

            const url = `https://${ServerEnv.MemberCalls2()}.unicity.com/hot/formatV2/JOR/feelGreat/createAccount${
                ServerEnv.ENV === 'DEV' ? '?dev=1' : '?emailDelay=1h'
            }`
            const response = await axios.post(httpHelper.Proxy({ originalUrl: url }), forms)

            return {
                ...response.data,
                form: forms
            }
        } catch (e) {
            console.error(e)

            return {
                success: false
            }
        }
    }

    SpecialAreasDeliveryFeeCondition(step) {
        let totalPrice = 0

        const shippingMethod = storeCheckout.GetShippingMethod()
        const city = storeCheckout.GetShippingForm().find(item => item.key === 'city_selection' && item.value)

        // has shipfee for delivery
        if (get(shippingMethod, 'api_value') === 'pickup' || !this.orderSummaryConfig.shippingWithoutMethod.displayStep.includes(step)) return totalPrice

        const products = toJS(StoreProducts.GetProducts())
        const onetimeItems = get(storeCheckout.GetCart(), 'onetime')
        let totalOnetimePV = 0

        if (!isEmpty(onetimeItems)) {
            const onetimeProducts = Object.keys(onetimeItems)
            onetimeProducts.forEach(item => {
                const productPV = get(products, [item, 'onetime', 'pv'], 0)
                const productQty = get(onetimeItems, item, 0)
                totalOnetimePV = totalOnetimePV + productPV * productQty
            })
        }

        console.log('city', city)

        if (['amman'].includes(get(city, 'value', '').toLowerCase())) {
            totalPrice = 2.5
        } else if (['zarqa'].includes(get(city, 'value', '').toLowerCase())) {
            totalPrice = 3
        } else {
            totalPrice = 4
        }

        if (totalOnetimePV > 50) {
            totalPrice = 0
        }

        return totalPrice
    }
    constructor() {
        StorePaymentStep.SetPaymentSteps(this.PaymentStep)
        makeAutoObservable(this)
    }
}

export default Jordan
