import { useTranslate } from 'GlobalStores/Cores/Translator/useTranslate'
import { observer, useLocalObservable } from 'mobx-react-lite'
import { useCallback, useEffect } from 'react'
import { GetReferralCode } from './APIs'
import storeAuth from 'GlobalStores/User/StoreAuth'
import { get } from 'lodash'
import { Card, Skeleton, Stack, ThemeProvider, Typography } from '@mui/material'
import styled from '@emotion/styled'
import EnrollLinkBox from './EnrollLinkBox'
import ELinkBox from './ELinkBox'
import storeFeelGreat from 'GlobalStores/FeelGreat/StoreFeelGreat'
import FeelGreatLinkBox from './FeelgreatLinkBox'
import { MainTemplate } from 'Components/Template/MainTemplate'
import { autorun, reaction, runInAction } from 'mobx'
import UFGLinkBox from './UFGLinkBox'
import storeReferral from 'GlobalStores/Referral/StoreReferral'

const ContentBox = observer(({ keyName, title, description, showdivider = true, linkBox = null }) => {
    return (
        <Section showdivider={showdivider}>
            <Stack mt={'30px'} mb={'10px'}>
                <Typography variant="title4" component="h3">
                    {title}
                </Typography>
            </Stack>
            <Stack mb={'20px'}>
                {keyName === 'distributor_enrollment' ? (
                    <Typography variant="title4" component="p" color="rgb(90, 143, 195)" fontSize={'14px'} fontWeight={400}>
                        {description}
                    </Typography>
                ) : (
                    <Typography variant="subtitle1" component="p" color={'rgb(90, 143, 195)'} fontSize={'14px'}>
                        {description}
                    </Typography>
                )}
            </Stack>
            {linkBox}
        </Section>
    )
})

const referralComponents = {
    distributor_enrollment: EnrollLinkBox,
    ble: ELinkBox,
    feelgreat: FeelGreatLinkBox,
    ble_feelgreatLink: FeelGreatLinkBox,
    ufg: UFGLinkBox
}

const ReferralLinks = observer(() => {
    const translate = useTranslate()

    const localObserver = useLocalObservable(() => ({
        loading: true,
        referralCode: '',
        isInited: false,
        components: []
    }))

    // const initCurrentUrl = useCallback(async () => {
    //     try {
    //         const { data } = await GetReferralCode(storeAuth.GetToken())
    //         const referralCode = get(data, 'items[0].referralCode', '')

    //         localObserver.loading = false
    //     } catch (error) {
    //         console.log(error)
    //     }
    // }, [localObserver])

    // useEffect(() => {
    //     if (localObserver.referralCode.length === 0) {
    //         localObserver.loading = true
    //         initCurrentUrl()
    //     }
    // }, [localObserver, initCurrentUrl])

    useEffect(() => {
        const disposer = reaction(() => storeReferral.GetReferral(),
        (newValue, prevValue) => {
            if (newValue !== prevValue) {
                runInAction(() => {
                    localObserver.referralCode = newValue.referralCode
                    localObserver.loading = false
                })
            }
        })

        return () => disposer()
    }, [localObserver])

    useEffect(() => {
        autorun(disposer => {
            if (storeFeelGreat.contents.referralLinksSorted.length > 0) {
                storeFeelGreat.contents.referralLinksSorted = storeFeelGreat.contents.referralLinksSorted.filter(item => {
                    return item.isEnable && item.allowStatuses.includes(storeAuth.userStatus)
                })
                disposer.dispose()
            }
        })
    }, [])

    return (
        <ThemeProvider theme={MainTemplate}>
            <Wrapper style={{ width: 'auto' }}>
                {localObserver.loading ? (
                    <Stack width={'auto'}>
                        <Title>
                            <Stack width="250px">
                                <Typography variant="h2">
                                    <Skeleton animation="wave" />
                                </Typography>
                            </Stack>
                        </Title>
                        <DistributorSection mb={'20px'}>
                            <Typography variant="subtitle1" component="h3">
                                <Skeleton animation="wave" width={'25%'} />
                            </Typography>
                            <Typography variant="subtitle1" component="p">
                                <Skeleton animation="wave" width={'70%'} />
                            </Typography>
                            <Typography variant="h2">
                                <Skeleton animation="wave" width={'90%'} />
                            </Typography>
                        </DistributorSection>

                        <FeelGreatSection>
                            <Stack mt={'30px'} mb={'10px'} />
                            <Typography variant="subtitle1" component="h3">
                                <Skeleton animation="wave" width={'25%'} />
                            </Typography>
                            <Typography variant="subtitle1" component="p">
                                <Skeleton animation="wave" width={'70%'} />
                            </Typography>
                            <Typography variant="h2">
                                <Skeleton animation="wave" width={'90%'} />
                            </Typography>
                            <Stack mb={'20px'} />
                        </FeelGreatSection>

                        <FeelGreatSection>
                            <Stack mt={'30px'} mb={'10px'} />
                            <Typography variant="subtitle1" component="h3">
                                <Skeleton animation="wave" width={'25%'} />
                            </Typography>
                            <Typography variant="subtitle1" component="p">
                                <Skeleton animation="wave" width={'70%'} />
                            </Typography>
                            <Typography variant="h2">
                                <Skeleton animation="wave" width={'90%'} />
                            </Typography>
                            <Stack mb={'20px'} />
                        </FeelGreatSection>
                    </Stack>
                ) : (
                    <>
                        <Title>
                            <Typography
                                variant="h2"
                                component="h2"
                                sx={{
                                    fontSize: {
                                        xs: '24px',
                                        md: '32px'
                                    },
                                    fontWeight: 700,
                                    textAlign: 'left',
                                    textWrap: ''
                                }}>
                                {translate('referral_program')}
                            </Typography>
                        </Title>
                        {storeFeelGreat.contents.referralLinksSorted.map((item, index) => {
                            const LinkBox = referralComponents[item.key]
                            if (item.isEnable && item.allowStatuses.includes(storeAuth.userStatus)) {
                                return (
                                    <ContentBox
                                        index={index}
                                        key={item.key}
                                        keyName={item.key}
                                        title={translate(`${item.key}_title`)}
                                        description={translate(`${item.key}_description`)}
                                        showdivider={(storeFeelGreat.contents.referralLinksSorted.length > 1 && index > 0).toString()}
                                        linkBox={<LinkBox referralCode={localObserver.referralCode} />}
                                    />
                                )
                            } else {
                                return null
                            }
                        })}
                    </>
                )}
            </Wrapper>
        </ThemeProvider>
    )
})

const Wrapper = styled(Card)`
    padding-top: 25px;
    padding-bottom: 30px;

    /* padding: 25px 15px 30px 15px;

    @media screen and (min-width: 768px) {
        padding: 25px 30px 30px 30px;
    } */
`
const Title = styled(Stack)`
    color: #003b6f;
    padding: 0px 15px;

    @media screen and (min-width: 768px) {
        padding: 0px 30px;
    }
`
const DistributorSection = styled(Stack)`
    margin-top: 30px;
    padding: 0px 15px;

    @media screen and (min-width: 768px) {
        padding: 0px 30px;
    }
`

const FeelGreatSection = styled(Stack)`
    padding: 0px 15px;

    @media screen and (min-width: 768px) {
        padding: 0px 30px;
    }
    @media screen and (max-width: 900px) {
        position: relative;
    }
    &::before {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        border-bottom: ${props => (props.showdivider ? '1px dashed #e5eef7' : 'none')};
    }
`

const Section = styled(Stack)`
    padding: 0px 15px;

    @media screen and (min-width: 768px) {
        padding: 0px 30px;
    }
    @media screen and (max-width: 900px) {
        position: relative;
    }
    &::before {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        border-bottom: ${props => (props.showdivider === 'true' ? '1px dashed #e5eef7' : 'none')};
    }
`

export default ReferralLinks
