import StoreAuth from 'GlobalStores/User/StoreAuth'
import { observer } from 'mobx-react-lite'
import LoginElem from '../Elements/Login/Login'
import { Navigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import MobileLayoutWrapper from 'Components/Elements/UserAccountMenu/MobileLayoutWrapper'
import storeCountry from 'GlobalStores/Cores/County/StoreCountry'

const Login = observer(() => {
    const [canSignup, setCanSignup] = useState(true)

    useEffect(() => {
        if (['JPN'].includes(storeCountry.Country3())) {
            setCanSignup(false)
        }
        if (StoreAuth.IsAuthorized()) {
            return <Navigate to={-1} replace />
        }
    }, [])

    return (
        <MobileLayoutWrapper>
            <LoginElem canSignup={canSignup} />
        </MobileLayoutWrapper>
    )
})

export default Login
