import { observer } from 'mobx-react-lite'
import { Stack, Typography, createTheme, ThemeProvider, Box } from '@mui/material'
import ResponseCard from '../Elements/ResetPassword/ResponseCard'
import { ReactComponent as SuccessIcon } from '../Elements/ResetPassword/Assets/success.svg'
import { ReactComponent as WrongIcon } from '../Elements/ResetPassword/Assets/wrong.svg'
import { DefaultButton } from 'Components/Elements/Forms'
import { useNavigate } from 'react-router-dom'
import { useTranslate } from 'GlobalStores/Cores/Translator/useTranslate'

const customTheme = createTheme({
    breakpoints: {
        values: {
            xs: 320, // phone
            sm: 768, // tablets
            md: 900 // small laptop
        }
    },
    palette: {
        primary: {
            main: '#1976d2'
        },
        secondary: {
            main: '#FFF'
        },
        button: {
            main: '#5A8FC4',
            disabled: 'rgba(90, 143, 196, .3)'
        }
    },
    typography: {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        h3: {
            color: '#0B3B61',
            fontFamily: 'Inter',
            fontStyle: 'normal',
            fontWeight: 700,
            fontSize: '32px'
        },
        label: {
            color: '#94A2B4',
            fontSize: 12,
            fontWeight: 500
        },
        link: {
            cursor: 'pointer',
            textAlign: 'center',
            fontFamily: 'Inter',
            fontStyle: 'normal',
            color: '#153862',
            fontWeight: 400,
            fontSize: '14px',
            lineHeight: '17px',
            textDecoration: 'underline'
        },
        caption: {
            fontFamily: 'Inter',
            fontStyle: 'normal',
            textAlign: 'center',
            fontWeight: 500,
            fontSize: '14px',
            lineHeight: '17px',
            color: '#003764'
        }
    }
})

const NewPasswordSuccess = observer(({ type }) => {
    const navigate = useNavigate()
    const translate = useTranslate()
    const successDetail = (
        <Typography
            variant="h5"
            sx={{
                fontSize: {
                    xs: '12px',
                    sm: '14px'
                },
                fontWeight: 400,
                textAlign: {
                    xs: 'start',
                    sm: 'center'
                },
                color: '#153862',
                width: '100%'
            }}>
            Your Unicity account password has been reset.
        </Typography>
    )

    const errorDetail = (
        <Typography
            variant="h5"
            sx={{
                fontSize: {
                    xs: '12px',
                    sm: '14px'
                },
                fontWeight: 400,
                textAlign: {
                    xs: 'start',
                    sm: 'center'
                },
                color: '#153862',
                width: '100%'
            }}>
            We're having trouble processing your password reset request. Please try again later or contact our customer{' '}
            <a href="#" style={{ color: '#153862', textUnderlineOffset: '4px' }}>
                support team.
            </a>
        </Typography>
    )

    const successButton = (
        <Box
            component="form"
            noValidate
            autoComplete="off"
            sx={{
                width: {
                    xs: '100%',
                    sm: '279px'
                }
            }}
            mt="50px"
            onClick={() => navigate(`/login`)}>
            <DefaultButton fullWidth={true} titleText={translate('new_password_login')} backgroundcolor={'#153862'} />
        </Box>
    )

    const errorButton = (
        <Box
            component="form"
            noValidate
            autoComplete="off"
            sx={{
                width: {
                    xs: '100%',
                    sm: '279px'
                }
            }}
            mt="50px"
            onClick={() => navigate(`/reset-password`)}>
            <DefaultButton fullWidth={true} titleText={translate('reset_password')} backgroundcolor={'#153862'} />
        </Box>
    )

    return (
        <ThemeProvider theme={customTheme}>
            <Stack bgcolor={'#D1D9E2'} py="18px" alignItems={'center'} sx={{ minHeight: '100vh', padding: '18px 3.5em' }}>
                {type === 'success' ? (
                    <ResponseCard title={translate('success') + '!'} icon={<SuccessIcon />} detail={successDetail} button={successButton} />
                ) : (
                    <ResponseCard title={translate('something_went_wrong')} icon={<WrongIcon />} detail={errorDetail} button={errorButton} />
                )}
            </Stack>
        </ThemeProvider>
    )
})

export default NewPasswordSuccess
