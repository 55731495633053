import { ReCAPTCHAV2QA_KEY, ReCAPTCHAV2_KEY } from 'Utils/Utils'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useRef, useState } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import storeReCaptcha from '../../../GlobalStores/StoreReCaptcha'
import { Skeleton, Stack } from '@mui/material'

const ReCaptcha = observer(props => {
    const [reCaptchaValue, setReCaptchaValue] = useState(false)
    const [loading, setLoading] = useState(true)

    const liveEnv = ['fg.unicity.com']
    const ReCaptChaKey = liveEnv.includes(window.location.hostname) ? ReCAPTCHAV2_KEY : ReCAPTCHAV2QA_KEY

    const reCaptchaVerify = async token => {
        if (token === null) {
            setReCaptchaValue(false)
            return
        }
        setReCaptchaValue(true)
        return
    }
    const handleLoading = () => {
        const timeOut = setTimeout(setLoading(false), 1200)

        clearTimeout(timeOut)
    }

    useEffect(() => {
        props.handleReCaptCha(reCaptchaValue)
    }, [reCaptchaValue])

    useEffect(() => {
        setReCaptchaValue(false)
    }, [storeReCaptcha.currentLanguage])

    return (
        <>
            <ReCAPTCHA
                onChange={reCaptchaVerify}
                sitekey={ReCaptChaKey}
                hl={storeReCaptcha.currentLanguage}
                asyncScriptOnLoad={handleLoading}
                style={{ display: loading ? 'none' : 'flex' }}
            />
            {loading && <Skeleton variant="rounded" width={'304px'} height={'78px'} sx={{ margin: '0', borderRadius: '12px' }} />}
        </>
    )
})

export default ReCaptcha
