import { Box, Fade, Modal, Stack } from '@mui/material'
import { AlreadyAccount } from 'Components/Elements/SignUp/SignUp'
import storeAuth from 'GlobalStores/User/StoreAuth'
import { observer } from 'mobx-react-lite'
import { Suspense } from 'react'
import { lazy } from 'react'
import pMinDelay from 'p-min-delay'
import storeCheckout from 'GlobalStores/Checkout/StoreCheckout'
import styled from '@emotion/styled'
import Login from 'Components/Elements/Login/Login'
import StoreCountry from 'GlobalStores/Cores/County/StoreCountry'
import StoreReferral from 'GlobalStores/Referral/StoreReferral'

const PreSummaryBoxLazyLoad = lazy(() => pMinDelay(import('../../Elements/Checkout/PreSummaryBox'), 0))

const PhillipinesCheckoutTheme = observer(({ Sections, step, onPlaceOrder, openLoginModal, setOpenLoginModal }) => {
    const notShowLogin = () => {
        return ['JPN'].includes(StoreCountry.Country3()) && StoreReferral.HasReferral()
    }
    return (
        <PhStackStyled stepPaymentOrReview={step}>
            <Suspense fallback={<div></div>}>
                <Stack>{Sections(step)}</Stack>
            </Suspense>
            <Stack>
                <Suspense fallback={<div></div>}>
                    <PreSummaryBoxLazyLoad step={step} edit={true} isReady={storeCheckout.isReadyToPlaceOrder} onPlaceOrder={onPlaceOrder} />
                </Suspense>
            </Stack>
            {step === 'create-account' && (
                <>
                    <DesktopAlreadyAccount>{!notShowLogin() && <AlreadyAccount setOpenLoginModal={setOpenLoginModal} />}</DesktopAlreadyAccount>

                    <Modal
                        open={openLoginModal}
                        onClose={() => {
                            if (storeAuth.loadingLogin) return
                            setOpenLoginModal(false)
                        }}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description">
                        <Fade in={openLoginModal}>
                            <Box
                                sx={{
                                    position: 'absolute',
                                    top: 'calc(50% - 40px)',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    padding: '0px'
                                }}>
                                <Login showFooter={false} setOpenLoginModal={setOpenLoginModal} />
                            </Box>
                        </Fade>
                    </Modal>
                </>
            )}
        </PhStackStyled>
    )
})

const StackStyled = styled.div`
    padding-top: 40px;
    padding-bottom: 40px;
    min-height: 50vh;
    display: flex;
    flex-direction: ${props => (props.stepPaymentOrReview ? 'column' : 'column-reverse')};
    justify-content: ${props => (props.stepPaymentOrReview ? 'flex-start' : 'flex-end')};
    gap: 20px;

    @media screen and (min-width: 399px) {
        padding-top: 40px;
    }

    @media screen and (min-width: 600px) {
        padding-top: 60px;
    }

    @media screen and (min-width: 1200px) {
        display: grid;
        grid-template-columns: 770px 370px;
        gap: 22px;
        padding-top: 40px;
    }
`

const PhStackStyled = styled(StackStyled)`
    flex-direction: ${props => (props.stepPaymentOrReview === 'payment' ? 'column' : 'column-reverse')};
    justify-content: ${props => (props.stepPaymentOrReview ? 'flex-start' : 'flex-end')};

    @media screen and (min-width: 768px) {
        padding-top: 40px;
        /* flex-direction: row;
        justify-content: center; */
        gap: 20px;
    }
`

const DesktopAlreadyAccount = styled('div')`
    display: block;
    @media screen and (min-width: 1200px) {
        display: none;
    }
`

export default PhillipinesCheckoutTheme
