import { Box, createTheme, Divider, Stack, styled, ThemeProvider, Typography, Modal, SvgIcon } from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import * as yup from 'yup'
import StoreAuth from 'GlobalStores/User/StoreAuth'

import { DefaultButton, DefaultInput } from '../Forms'
import StoreCheckout from 'GlobalStores/Checkout/StoreCheckout'
import { T, useTranslate } from 'GlobalStores/Cores/Translator/useTranslate'
import _, { get } from 'lodash'
import { storeElement } from 'GlobalStores/StoreElement'
import Errors from '../Errors/Errors'
import CloseIcon from '@mui/icons-material/Close'
import StoreReferral from 'GlobalStores/Referral/StoreReferral'
import StoreLoader from 'GlobalStores/Cores/Elements/StoreLoader'
import { toJS } from 'mobx'
import storeCredit from 'GlobalStores/StoreCredit'
import ReCaptcha from '../ReCaptcha/ReCaptcha'

const { observer } = require('mobx-react-lite')

const Login = observer(props => {
    const { showFooter = true, canSignup = true } = props

    // (_.get(pageConfig, 'signUp.disable'))
    const translate = useTranslate()
    const navigate = useNavigate()
    const [showLoginFooter, setShowLoginFooter] = useState(showFooter)
    const [loginFormError, setLoginFormError] = useState([])
    const [reCaptchaVerify, setReCaptchaVerify] = useState(false)

    const location = useLocation()

    const validationSchema = yup.object({
        unicityID: yup.string().required(translate('unicity_id_required')),
        password: yup.string().min(4, translate('minimum_four_char_password')).required(translate('password_is_required')),
        keepLoggedIn: yup.boolean()
    })

    const formik = useFormik({
        initialValues: {
            unicityID: '',
            password: '',
            keepLoggedIn: false
        },
        validationSchema: validationSchema,
        onSubmit: async (values, { setErrors }) => {
            try {
                StoreAuth.loadingLogin = true
                const response = await StoreAuth.Login(values)
                StoreAuth.allowCredit = response.allowCredit
                let isHasCredit = false
                Object.keys(response.allowCredit).map((v, k) => {
                    if (v === 'enable' && response.allowCredit[v] === true) {
                        StoreAuth.allowCredit = response.allowCredit
                        StoreAuth.allowCredit.enable = true
                        isHasCredit = true
                    }
                    return ''
                })

                if (isHasCredit === true) {
                    storeCredit.Init({ id: StoreAuth.GetId(), token: StoreAuth.GetToken() })
                }

                StoreCheckout.SetUseMemberPrice(true)
                StoreReferral.reset()

                if (StoreAuth.allowCredit?.enable) {
                    storeCredit.Init({ id: StoreAuth.GetId(), token: StoreAuth.GetToken() })
                }

                if (props.onCheckout && !StoreCheckout.GetIsLoginOnly()) {
                    props.onCheckout()
                } else if (['/checkout/create-account'].includes(location.pathname)) {
                    StoreLoader.SetLoadFormatApi(true)
                    navigate(`/checkout/shipping${location.search}`)
                } else {
                    if (location.pathname !== '/products') {
                        navigate('/user')
                    }
                }
                setTimeout(() => {
                    StoreAuth.loadingLogin = false
                }, 500)
            } catch (e) {
                if (get(e, 'response.data.error.error.status') === 401) {
                    setErrors({ password: translate('login_or_password_incorrect') })
                } else {
                    if (e.message && e.message === 'user_status_not_allowed') {
                        setErrors({
                            password: translate(e.message)
                        })
                    } else {
                        setErrors({
                            password: get(e, 'response.data.displayError.message', '') || get(e, 'response.data.message', 'Server under maintain!')
                        })
                    }
                }
                StoreAuth.loadingLogin = false
            }
        }
    })

    useEffect(() => {
        if (showFooter) {
            let pageConfig = toJS(StoreCheckout.CheckOutInstance().GetPageConfig())
            if (_.get(pageConfig, 'signUp.disable')) {
                setShowLoginFooter(false)
            }
        }
    }, [])

    useEffect(() => {
        let error = []
        if (formik.touched.unicityID && Boolean(formik.errors.unicityID)) {
            error.push(formik.errors.unicityID)
        }

        if (formik.touched.password && Boolean(formik.errors.password)) {
            error.push(formik.errors.password)
        }

        setLoginFormError(error)
    }, [formik.errors, formik.touched])

    const handleReCaptCha = (value) => {
        setReCaptchaVerify(value)
    }

    return (
        <ThemeProvider theme={customTheme}>
            <LoginWrapper
                style={{
                    marginTop: showLoginFooter === false || !props.canSignup ? '0px' : '10px',
                    height: 'fit-content',
                    boxSizing: 'border-box'
                }}
                isLoginPage={window.location.pathname.includes('login')}>
                <LoginStackWrapper
                    sx={{
                        borderBottomLeftRadius: {
                            xs: '12px',
                            sm: showLoginFooter === false || !props.canSignup ? '12px' : '0px',
                            md: showLoginFooter === false || !props.canSignup ? '12px' : '0px'
                        },
                        borderBottomRightRadius: {
                            xs: '12px',
                            sm: showLoginFooter === false || !props.canSignup ? '12px' : '0px',
                            md: showLoginFooter === false || !props.canSignup ? '12px' : '0px'
                        },
                        boxSizing: 'border-box'
                    }}
                    isLoginPage={window.location.pathname.includes('login')}>
                    {showFooter === false && (
                        <Box display={'flex'} justifyContent={'flex-end'}>
                            <SvgIcon
                                component={CloseIcon}
                                cursor={StoreAuth.loadingLogin ? 'not-allowed' : 'pointer'}
                                onClick={() => {
                                    if (StoreAuth.loadingLogin) return
                                    props.setOpenLoginModal(false)
                                }}
                                opacity={'0.2'}
                            />
                        </Box>
                    )}
                    <Typography variant="h3" align="center">
                        <T>log_in</T>
                    </Typography>
                    <Stack
                        mt="30px"
                        mx="auto"
                        sx={{
                            width: {
                                xs: '300px',
                                sm: '370px',
                                md: '370px'
                            }
                        }}>
                        <form onSubmit={formik.handleSubmit} autoComplete="off" noValidate>
                            <Box noValidate sx={{ maxWidth: '100%' }}>
                                <DefaultInput
                                    autoComplete="off"
                                    id="unicityID"
                                    name="unicityID"
                                    label={translate('email_or_unicity_id')}
                                    value={formik.values.unicityID}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    type="email"
                                />
                            </Box>
                            <Box noValidate sx={{ maxWidth: '100%' }} mt="10px">
                                <DefaultInput
                                    autoComplete="current-password"
                                    id="password"
                                    name="password"
                                    label={translate('password')}
                                    type="password"
                                    value={formik.values.password}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                            </Box>

                            <Stack mt="30px" display={'flex'} alignItems={'center'} justifyContent={'center'}>
                                <ReCaptcha handleReCaptCha={handleReCaptCha} />
                            </Stack>

                            <Box mt="40px" width="100%" sx={{ display: 'flex', justifyContent: 'center' }}>
                                <DefaultButton
                                    disabled={!(formik.isValid && formik.dirty && !StoreAuth.loadingLogin && reCaptchaVerify)}
                                    type="submit"
                                    fullWidth={true}
                                    titleText={translate('log_in')}
                                    width="275px"
                                    isloading={StoreAuth.loadingLogin.toString()}
                                />
                            </Box>
                            {loginFormError.length ? (
                                <Stack mt="30px">
                                    <Errors list={loginFormError} />
                                </Stack>
                            ) : null}
                        </form>

                        <Box
                            mt={storeElement.dynamicWidth < 700 ? '30px' : '20px'}
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                marginBottom: showLoginFooter === false ? '20px' : '0px'
                            }}>
                            <Typography
                                variant="link"
                                onClick={() => {
                                    if (StoreAuth.loadingLogin) return
                                    navigate('/reset-password')
                                }}
                                sx={{ cursor: StoreAuth.loadingLogin ? 'not-allowed !important' : 'pointer' }}>
                                <T>forgot_password</T>
                            </Typography>
                        </Box>
                    </Stack>
                </LoginStackWrapper>
                {showLoginFooter !== false && props.canSignup && (
                    <LoginStackWrapper
                        sx={{
                            padding: storeElement.dynamicWidth < 700 ? '30px 0 0 0 !important' : '0px 0 40px 0 !important',
                            borderTopLeftRadius: '0px',
                            borderTopRightRadius: '0px',
                            backgroundColor: {
                                xs: 'transparent',
                                sm: '#fff',
                                md: '#fff'
                            },
                            width: '100%'
                        }}
                        isLoginPage={window.location.pathname.includes('login')}>
                        <Divider
                            sx={{
                                display: {
                                    xs: 'none',
                                    sm: 'block',
                                    md: 'block'
                                },
                                borderColor: 'rgba(153, 188, 223, 0.25)',
                                marginBottom: '30px'
                            }}
                        />
                        <Stack
                            mx="auto"
                            sx={{
                                width: '100%',
                                display: 'inherit'
                            }}>
                            <Box flexDirection={'column'} display="flex">
                                <Typography mb={'8px'} variant="caption">
                                    <T>dont_have_an_account</T>
                                </Typography>
                                <Typography
                                    variant="link"
                                    onClick={() => {
                                        if (StoreAuth.loadingLogin) return
                                        navigate('/sign-up')
                                    }}
                                    sx={{ cursor: StoreAuth.loadingLogin ? 'not-allowed !important' : 'pointer' }}>
                                    <T>sign_up</T>
                                </Typography>
                            </Box>
                        </Stack>
                    </LoginStackWrapper>
                )}
            </LoginWrapper>
        </ThemeProvider>
    )
})

const LoginWrapper = styled(Stack)`

    @media screen and (min-width: 375px) {
        width: 100%;
    }
    
    @media screen and (min-width: 900px) {
        padding: ${({ isLoginPage }) => (isLoginPage ? '0px 10%' : '')};
    }

    @media screen and (min-width: 1200px) {
        padding: ${({ isLoginPage }) => (isLoginPage ? '0px 20%' : '')};
    }

    @media screen and (min-width: 1600px) {
        padding: ${({ isLoginPage }) => (isLoginPage ? '0px 30%' : '')};
    }
`

const LoginStackWrapper = styled(Stack)`
    padding: ${({ isLoginPage }) => (isLoginPage ? '40px 20px 40px 20px' : '20px 20px 20px 20px')};
    background-color: #fff;
    border-radius: 12px;
    height: fit-content;
    width: 100%;

    @media screen and (min-width: 1200px) {
        padding: 40px 30px 30px 30px;
    }
`

const customTheme = createTheme({
    breakpoints: {
        values: {
            xs: 320, // phone
            sm: 700, // tablets
            md: 900 // small laptop
        }
    },
    palette: {
        primary: {
            main: '#1976d2'
        },
        secondary: {
            main: '#FFF'
        },
        button: {
            disabled: '#CDDDED'
        }
    },
    typography: {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        h3: {
            color: '#0B3B61',
            fontFamily: 'Inter',
            fontStyle: 'normal',
            fontWeight: 700,
            fontSize: '32px'
        },
        label: {
            color: '#94A2B4',
            fontSize: 12,
            fontWeight: 500
        },
        link: {
            cursor: 'pointer',
            textAlign: 'center',
            fontFamily: 'Inter',
            fontStyle: 'normal',
            color: '#153862',
            fontWeight: 400,
            fontSize: '14px',
            lineHeight: '17px',
            textDecoration: 'underline'
        },
        caption: {
            fontFamily: 'Inter',
            fontStyle: 'normal',
            textAlign: 'center',
            fontWeight: 500,
            fontSize: '14px',
            lineHeight: '17px',
            color: '#003764'
        }
    }
})

export default Login
