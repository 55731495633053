import { Button, Stack, styled } from '@mui/material'
import Loader from './Assets/Loader.svg'

const ConfirmBtn = ({
    onClick,
    disabled = false,
    titleText,
    isLoading,

}) => {

    return (
        <Stack>
            <ConfirmButton disableRipple={true} disabled={disabled} onClick={onClick}>
                {isLoading ? <Spinner src={Loader} alt="Loading" /> : titleText}
            </ConfirmButton>
        </Stack>
    )
}

export default ConfirmBtn

const ConfirmButton = styled(Button)`
    width: 100%;
    height: 48px;
    border-radius: 9999px;
    background-color: #079FF4;
    color: #fff;
    font-size: 20px;
    font-weight: 600;
    padding-top: 10px;
    padding-bottom: 10px;
    text-transform: none;
    font-family: 'Poppins';

    &:disabled {
        background-color: #CCDEEF;
        color: #fff;
        &:hover {
            background-color: #CCDEEF;
        }
    }

    &:hover {
        background-color: #079FF4;
    }
`

const Spinner = styled('img')({
    animation: 'rotation 1s infinite linear',
    '@keyframes rotation': {
        '0%': {
            transform: 'rotate(0deg)'
        },
        '100%': {
            transform: 'rotate(359deg)'
        }
    }
})