import { Backdrop, Box, CircularProgress, InputBase, InputAdornment, Stack, styled, ThemeProvider, Typography } from '@mui/material'

import StoreCheckout from 'GlobalStores/Checkout/StoreCheckout'
import StoreCountry from 'GlobalStores/Cores/County/StoreCountry'
import StoreLoader from 'GlobalStores/Cores/Elements/StoreLoader'
import { T, useTranslate } from 'GlobalStores/Cores/Translator/useTranslate'
import StoreReferral from 'GlobalStores/Referral/StoreReferral'
import StoreAuth from 'GlobalStores/User/StoreAuth'
import { get, includes, last, set } from 'lodash'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { GetFeelGreatProduct } from 'Services/FeelGreat/useFeelGreat'
import { Customers } from '../../../Services/Cores/Customers/Customers'
import { MainTemplate } from '../../Template/MainTemplate'
import PopperPopup from '../PopperPopup/PopperPopup'
import { ReactComponent as ArrowRightIcon } from './Assets/arrow-long-right.svg'
import StoreTranslate from 'GlobalStores/Cores/Translator/StoreTranslate'

const ReferralBar = observer(props => {
    const showReferralBox = ['', 'products', 'sign-up']
    const translate = useTranslate()
    const params = useParams()

    const [customerContact, setCustomerContact] = useState(null)
    const [openBackDrop, setOpenBackDrop] = useState(false)
    const [touched, setTouched] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')

    const location = useLocation()
    const navigate = useNavigate()
    useEffect(() => {
        if (params.customerId && !StoreAuth.IsAuthorized()) {
            GetCustomerData(params.customerId)
            navigate('/')
        }
    }, [params.customerId])

    const GetCustomerData = async customerId => {
        if (!customerId) return
        setOpenBackDrop(true)
        try {
            const referralResponse = await Customers.getAllowReferrer(customerId)
            const item = {
                ...referralResponse.data,
                unicity: referralResponse.data.baId,
                href: '',
                customerSite: {
                    href: ''
                },
                id: {
                    unicity: referralResponse.data.baId
                },
                profilePicture: {
                    href: ''
                },
                joinDate: '',
                status: '',
                type: '',
                entryPeriod: ''
            }
            const isAllow = get(referralResponse.data, 'isAllow')
            const invalidType = get(referralResponse.data, 'reason.type')

            if (invalidType) {
                setErrorMessage(referralResponse.data.reason.message)
            } else {
                if (isAllow) {
                    setErrorMessage('')
                    await StoreReferral.SetReferralData(item, get(item, 'baStatus', ''))
                    const settingResponse = await GetFeelGreatProduct(get(item, 'baStatus', ''))
                    StoreLoader.InitAfterLoadSetting(settingResponse.data)
                    StoreCheckout.SetUseReferralPrice(true)
                } else {
                    setTouched(true)
                }
            }
        } catch (error) {
            console.log('error', error)
            setTouched(true)
        }
        setOpenBackDrop(false)
    }

    // const GetCustomerImgProfile = async url => {
    //     try {
    //         const response = await Customers.GetCustomerImgProfile(url)
    //         const imgUrl = get(response.data, 'sizes[0].media')
    //         // console.log("imgUrl ", imgUrl);
    //         // setCustomerContact({...customerContact, img:imgUrl})
    //     } catch (error) {
    //         console.log('error', error)
    //     }
    // }

    useEffect(() => {
        const id = get(StoreReferral, 'referralData.id', '')
        if (!StoreReferral.HasReferral() && !StoreAuth.IsAuthorized() && id) {
            GetCustomerData(id)
        }
    }, [])

    const isShowBox = () => {
        let path = last(location.pathname.split('/'))
        if (path === StoreCountry.CountryLowerCase() || !isNaN(path)) {
            path = ''
        }

        return includes(showReferralBox, path)
    }

    return (
        !StoreCheckout.CheckOutInstance().GetNotShowReferralBar() && (
            <ThemeProvider theme={MainTemplate}>
                <Stack bgcolor={'#153862'} pt="18px" pb="18px">
                    <Box color={'#ffffff'} display="flex" justifyContent="center" textAlign="center">
                        {!StoreReferral.HasReferral() && isShowBox() ? (
                            <>
                                <ReferInput
                                    touched={touched}
                                    setTouched={setTouched}
                                    refervalid={StoreReferral.HasReferral()}
                                    onSubmitRef={GetCustomerData}
                                    customerId={params.customerId}
                                    errorMessage={errorMessage}
                                    setErrorMessage={setErrorMessage}
                                    placeholderMessage={translate(`enter_your_referal_code`)}
                                />
                                <div style={{ display: 'flex', marginLeft: '24px', height: '36px' }}>
                                    <PopperPopup
                                        title={translate('what_is_member_code')}
                                        caption={translate('member_invite_code_caption')}
                                        contactInfo={customerContact}
                                    />
                                </div>
                            </>
                        ) : (
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Typography variant="caption" color="#ffffff" alignSelf="center">
                                    <T>referred_by</T>{' '}
                                    {StoreReferral.HasReferral() && (
                                        <span style={{ fontWeight: 'bold' }}>
                                            {/* {StoreUser.CustomerData().GetNativeName(
                                            get(StoreReferral, 'referralData.humanName', {}),
                                            StoreTranslate.CurrentLanguage() === 'EN' ? 'fullName' : 'nativeName'
                                        )} */}
                                            {StoreTranslate.UseLanguage(get(StoreReferral, 'referralData.displayName', '')) ||
                                                get(StoreReferral, 'referralData.displayName.english', '') ||
                                                get(StoreReferral, 'referralData.displayName.native', '')}
                                        </span>
                                    )}
                                </Typography>
                            </div>
                        )}
                    </Box>
                </Stack>
                <BackdropShow isOpen={openBackDrop} />
            </ThemeProvider>
        )
    )
})

const BackdropShow = ({ isOpen }) => {
    return (
        <Backdrop
            sx={{
                color: '#fff',
                zIndex: theme => theme.zIndex.drawer + 1
            }}
            open={isOpen}>
            <CircularProgress color="inherit" />
        </Backdrop>
    )
}

const ReferInput = ({ touched, setTouched, refervalid = false, onSubmitRef, customerId = '', placeholderMessage, errorMessage, setErrorMessage }) => {
    const [refCode, setRefcode] = useState(customerId)

    const submitRefCode = async e => {
        e.preventDefault()
        await onSubmitRef(refCode)
    }

    return (
        <ReferInputWrapper>
            <form className="mainInput" onSubmit={submitRefCode}>
                <CustomRefInput
                    autoComplete="off"
                    name="refCode"
                    placeholder={placeholderMessage}
                    endAdornment={
                        <InputAdornment position="end">
                            <ArrowRightIcon
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                    onSubmitRef(refCode)
                                }}
                            />
                        </InputAdornment>
                    }
                    onChange={e => {
                        setRefcode(e.target.value)
                        setTouched(false)
                        setErrorMessage('')
                    }}
                    style={
                        touched
                            ? {
                                  color: !refervalid ? '#FD5C5C' : '#ffffff',
                                  border: !refervalid ? '1px solid #FD5C5C' : '1px solid #ffffff'
                              }
                            : {}
                    }
                />
            </form>
            {errorMessage && (
                <Typography mt={'8px'} variant="label" color={'#FD5C5C'}>
                    {errorMessage}
                </Typography>
            )}
            {touched && (
                <Typography display={!refervalid ? 'block' : 'none'} mt={'8px'} variant="label" color={'#FD5C5C'}>
                    <T>invalid_code_entered</T>: {refCode}
                </Typography>
            )}
        </ReferInputWrapper>
    )
}

const CustomRefInput = styled(InputBase)(({ theme, refervalid }) => ({
    width: '287px',
    height: '36px',
    opacity: 0.6,
    borderRadius: '6px',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 400,

    lineHeight: '16px',

    padding: '10px 16px',

    input: {
        fontSize: '12px'
    },

    border: '1px solid #ffffff',
    color: '#ffffff'

    // border: refervalid ? '1px solid #FD5C5C' : '1px solid #ffffff',
    // color: refervalid ? '#FD5C5C' : '#ffffff',
}))

const ReferInputWrapper = styled('div')`
    display: flex;
    flex-direction: column;
    .mainInput {
        display: flex;
        flex-direction: column;
    }
`

export default ReferralBar
