import { Box, Stack, styled, Paper, ThemeProvider, Typography, FormGroup, FormControlLabel, Modal, SvgIcon, Skeleton, Fade } from '@mui/material'
import axios from 'axios'
import { MainTemplate } from 'Components/Template/MainTemplate'
import StoreCheckout from 'GlobalStores/Checkout/StoreCheckout'
import StoreCountry from 'GlobalStores/Cores/County/StoreCountry'
import { T, useTranslate } from 'GlobalStores/Cores/Translator/useTranslate'
import StoreFeelGreat from 'GlobalStores/FeelGreat/StoreFeelGreat'
import StoreReferral from 'GlobalStores/Referral/StoreReferral'
import { storeElement } from 'GlobalStores/StoreElement'
import StoreAuth from 'GlobalStores/User/StoreAuth'
import { getNativeName, useSizeWindow } from 'Helpers/GlobalHelpers'
import { ObjectHelpers } from 'Helpers/ObjectHelpers'
import { get, isObject, isString } from 'lodash'
import { reaction, runInAction, toJS } from 'mobx'
import { observer, useLocalObservable } from 'mobx-react-lite'
import React, { useLayoutEffect, useState } from 'react'
import { useEffect } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { FeelGreatAPI } from 'Services/FeelGreat/APIs'
import CardFrontDrop from '../CardFrontDrop/CardFrontDrop'
import Errors from '../Errors/Errors'
import { DefaultButton } from '../Forms'
import DefaultCheckboxSVG from '../Forms/DefaultCheckboxSVG'
import { FormSelector } from '../Forms/FormSelector'
import { ReactComponent as IconFireWorks } from './Assets/IconFireWorks.svg'
import { ReactComponent as IconClose } from './Assets/IconClose.svg'
import { ServerEnv } from 'Configs/ServerEnv'
import { ModalTermsAndCondition } from '../TermAndCondition/ModalTermAndCondition'
import Login from '../Login/Login'
import StoreTranslate from 'GlobalStores/Cores/Translator/StoreTranslate'
import HttpHelper from 'Services/HttpHelper'
import ReCaptcha from '../ReCaptcha/ReCaptcha'

const SignUp = observer(props => {
    const navigate = useNavigate()
    const [openLoginModal, setOpenLoginModal] = useState(false)

    useEffect(() => {
        return () => {
            StoreCheckout.duplicatedEmail = false
            StoreCheckout.duplicatedPhone = false
        }
    }, [])

    return (
        <Box maxWidth={'770px'} minHeight={'80vh'}>
            <AlreadyAccount setOpenLoginModal={setOpenLoginModal} />
            <CreateAccount {...props} setOpenLoginModal={setOpenLoginModal} />
            <Modal
                open={openLoginModal}
                onClose={() => setOpenLoginModal(false)}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description">
                <Box
                    sx={{
                        position: 'absolute',
                        top: 'calc(50% - 40px)',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        padding: '0px'
                    }}>
                    <Login showFooter={false} setOpenLoginModal={setOpenLoginModal} />
                </Box>
            </Modal>
        </Box>
    )
})

export const AlreadyAccount = observer(props => {
    const navigate = useNavigate()
    const location = useLocation()

    return (
        <Stack position={'relative'}>
            <CardFrontDrop />
            <StackAlreadyAccountStyled>
                <Typography variant="caption" fontWeight={500} color="#003764">
                    <T>Already_have_an_account</T>
                </Typography>

                <Typography
                    variant="link"
                    sx={{ fontWeight: 400, cursor: 'pointer' }}
                    color="#153862"
                    onClick={() => (location.pathname === '/sign-up' ? navigate('/login') : props.setOpenLoginModal(true))}>
                    <T>login</T>
                </Typography>
            </StackAlreadyAccountStyled>
        </Stack>
    )
})

const CreateAccount = observer(props => {
    const [open, setOpen] = useState(false)

    const convertKeys = !['THA', 'TUR'].includes(StoreCountry.Country3())

    const translate = useTranslate()
    const handleOpen = () => setOpen(true)
    const handleClose = () => setOpen(false)
    const navigate = useNavigate()
    const [loadingForm, setLoadingForm] = useState(true)
    const [agreeTerms1, setAgreeTerms1] = useState(false)
    const [reCaptchaVerify, setReCaptchaVerify] = useState(false)

    useEffect(() => {
        const timer = setTimeout(() => {
            setLoadingForm(false)
        }, 1000)
        return () => clearTimeout(timer)
    }, [])

    const account = useLocalObservable(() => ({
        type: 'enroll',
        fullname: '',
        fullname_native: '',
        firstname_en: '',
        lastname_en: '',
        email: '',
        phone: '',
        password: '',
        period: ''
    }))

    const onChange = (item, value) => {
        let val = value ? (isString(value) ? value : value.target.value) : ''
        let { key } = item
        const regx = get(item, 'args.regx', null)
        if (regx) {
            const regex = new RegExp(regx, 'i')
            if (!regex.test(val)) {
                val = ''
            }
        }

        runInAction(() => {
            item.value = val
            if (key === 'firstname' && convertKeys) {
                account.firstname_en = item.value
            } else if (key === 'lastname' && convertKeys) {
                account.lastname_en = item.value
            } else if (key === 'fullName') {
                account.fullname = item.value
            } else if (key === 'fullName@ja') {
                account.fullname_native = item.value
            } else {
                account[key] = item.value
            }
        })
    }

    const handleAgreeTerms1 = e => {
        const { checked } = e.target
        setAgreeTerms1(checked)
    }

    const handleReCaptCha = value => {
        setReCaptchaVerify(value)
    }

    const onSubmit = async () => {
        runInAction(() => {
            StoreCheckout.checkoutErrors = []
            storeElement.showCardFrontDrop = true
        })
        try {
            const qs = { isSkipValidateAddress: 1 }
            if (ServerEnv.ENV === 'DEV') {
                qs.dev = 1
            }

            qs['emailDelay'] = '1h'

            const queryString = `?${Object.entries(qs)
                .map(e => e.join('='))
                .join('&')}`

            const url = `https://${ServerEnv.MemberCalls2()}.unicity.com/hot/formatV2/${StoreCountry.Country3()}/feelGreat/createAccount${queryString}`
            const periodResponse = await FeelGreatAPI.GetPeriod(StoreCountry.Country2())
            account.period = periodResponse.data.entryPeriod
            if (StoreReferral.HasReferral()) {
                account.referral_id = StoreReferral.Customer().CustomerId
            }

            const response = await axios.post(HttpHelper.Proxy({ originalUrl: url }), account)
            if (response.data.success === false) {
                if (response.data.isDuplicatedEmail) {
                    runInAction(() => {
                        StoreCheckout.duplicatedEmail = true
                    })
                }

                // if (response.data.Message.length > 0) {
                //     runInAction(() => {
                //         StoreCheckout.checkoutErrors = response.data.Message
                //     })
                // }

                if (response.data.Message_v2.length > 0) {
                    runInAction(() => {
                        StoreCheckout.checkoutErrors = response.data.Message_v2
                    })
                }

                if (response.isDuplicatedPhone) {
                    runInAction(() => {
                        StoreCheckout.duplicatedPhone = true
                        storeElement.showCardFrontDrop = false

                        // StoreCheckout.checkoutErrors.push('duplicate_phone_number')
                    })
                }

                runInAction(() => {
                    storeElement.showCardFrontDrop = false
                })
            } else {
                await StoreAuth.Login({ unicityID: response.data.ba_id, password: account.password })

                runInAction(() => {
                    storeElement.showCardFrontDrop = false
                })

                navigate('/sign-up-success')
            }
        } catch (e) {
            console.error(e)
            runInAction(() => {
                // StoreCheckout.checkoutErrors = response.data.Message
                storeElement.showCardFrontDrop = false
            })
        }
    }

    return (
        <ThemeProvider theme={MainTemplate}>
            <Stack position={'relative'} mt="20px">
                <CardFrontDrop />
                <StackCreateAccountStyled>
                    <Stack>
                        <Typography lineHeight="42px" fontSize={storeElement.dynamicWidth < 700 ? '24px' : '32px'}>
                            <T>create_your_account</T>
                        </Typography>

                        {StoreReferral.HasReferral() && (
                            <Stack mt={'30px'} sx={{ background: '#E8F9FF', borderRadius: '6px', width: '100%' }}>
                                <Typography
                                    variant="caption"
                                    sx={{
                                        padding: '12px 15px',
                                        textAlign: storeElement.dynamicWidth > 700 ? 'center' : '',
                                        color: '#0BA4D4',
                                        fontSize: '13px',
                                        lineHeight: '16px'
                                    }}>
                                    <span
                                        dangerouslySetInnerHTML={{
                                            __html: translate('invited_by_description').replace(
                                                '{{referral_name}}',
                                                StoreTranslate.UseLanguage(StoreReferral.Customer()?.DisplayName())
                                            )
                                        }}
                                    />
                                    &nbsp;
                                    <strong
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => {
                                            handleOpen()
                                        }}>
                                        <u>
                                            <T>the_product_learn_more</T>
                                        </u>
                                    </strong>
                                </Typography>
                            </Stack>
                        )}
                        <ThemeSelector countryCapitalize={StoreCountry.CountryCapitalize()}>
                            {loadingForm === true ? (
                                <SkeletonLoading />
                            ) : (
                                <PaperStyled className="shipping-address--dynamic-form">
                                    {StoreFeelGreat.GetCheckoutForms().createYourAccount?.inputs.map(item => {
                                        return <Box key={item.key}>{FormSelector(translate, item, onChange)}</Box>
                                    })}
                                </PaperStyled>
                            )}
                            {StoreCheckout.GetCheckoutErrors().length > 0 && (
                                <Stack mt="40px">
                                    <Errors list={StoreCheckout.GetCheckoutErrors()} toTranslate={false} />
                                </Stack>
                            )}
                            {(StoreCheckout.duplicatedEmail || StoreCheckout.duplicatedPhone) && (
                                <Stack width="100%" mt="20px">
                                    <Errors>
                                        <Box textAlign={'center'} pl="30px" pr="30px">
                                            <Box>
                                                <Typography variant="error">
                                                    {/* <T>duplicated_email_and_go_login</T> */}
                                                    {StoreCheckout.duplicatedPhone ? (
                                                        <T>duplicated_email_or_phone_and_go_login</T>
                                                    ) : (
                                                        <T>duplicated_email_and_go_login</T>
                                                    )}
                                                </Typography>{' '}
                                                <Typography variant="link" onClick={() => props.setOpenLoginModal(true)}>
                                                    Login
                                                </Typography>
                                                <Typography variant="error">?</Typography>
                                            </Box>
                                        </Box>
                                    </Errors>
                                </Stack>
                            )}
                        </ThemeSelector>
                        <StackAgreeAndVerify>
                            {/* term and condition */}
                            <TermsAndCondition handleAgreeTerms1={handleAgreeTerms1} agreeTerms1={agreeTerms1} />
                            {/* reCAPTCHA V2 */}
                            <ReCaptcha handleReCaptCha={handleReCaptCha} />
                        </StackAgreeAndVerify>

                        {!props?.standalone ? (
                            <Stack mt={storeElement.dynamicWidth < 700 ? '30px' : '40px'} alignItems={'center'}>
                                {/* create account button */}
                                <DefaultButton
                                    width={storeElement.dynamicWidth < 700 ? '275px' : '280px'}
                                    titleText={translate('create_your_account')}
                                    onClick={onSubmit}
                                    isloading={storeElement.showCardFrontDrop.toString()}
                                    disabled={agreeTerms1 && reCaptchaVerify === true ? false : true}
                                />
                            </Stack>
                        ) : null}
                    </Stack>

                    <ModalLearnMore open={open} handleClose={handleClose} />
                </StackCreateAccountStyled>
            </Stack>
        </ThemeProvider>
    )
})

const TermsAndCondition = observer(props => {
    const [open, setOpen] = useState(false)

    const translate = useTranslate()
    const handleOpen = () => setOpen(true)
    const handleClose = () => setOpen(false)

    return (
        <Box>
            <Stack sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <FormGroup>
                    <FormControlLabel
                        style={{ margin: 0 }}
                        control={
                            <DefaultCheckboxSVG
                                onChange={props.handleAgreeTerms1}
                                defaultChecked
                                disableFocusRipple
                                disableRipple
                                disableTouchRipple
                                style={{
                                    padding: '0 10px 0 0'
                                }}
                                checked={props.agreeTerms1}
                            />
                        }
                        label={
                            <Typography variant="label" color="#153862" fontWeight={400} component="span" justifyContent={'center'} pt="4px">
                                <T>i_agree_unicity_member</T>&nbsp;
                                <a
                                    style={{
                                        color: '#153862',
                                        fontFamily: 'Inter',
                                        fontStyle: 'normal',
                                        fontWeight: 400,
                                        textUnderlineOffset: '4px'
                                    }}
                                    href={StoreTranslate.UseLanguage(StoreFeelGreat.contents.termOfUse[StoreFeelGreat.contents.termOfUse.usageType])}
                                    target={get(StoreFeelGreat, 'contents.termOfUse.openTarget', '')}>
                                    <T>terms_conditions_feelgreat</T>
                                </a>
                            </Typography>
                        }
                    />
                </FormGroup>
            </Stack>

            <ModalTermsAndCondition open={open} handleClose={handleClose} />
        </Box>
    )
})

export const ModalLearnMore = observer(props => {
    const { open, handleClose } = props
    const { width } = useSizeWindow()
    const translate = useTranslate()

    return (
        <Modal sx={{ padding: '0 20px' }} open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <BoxModalStyled>
                <Stack padding={width < 700 ? '30px 18px' : '30px'}>
                    <Stack alignItems={'flex-end'}>
                        <IconClose cursor={'pointer'} onClick={() => handleClose()} />
                    </Stack>
                    <Stack alignItems={width < 700 ? 'center' : 'initial'}>
                        <IconFireWorks />
                    </Stack>

                    <Stack mt="25px">
                        <Typography variant="h3" textAlign={width < 700 ? 'center' : 'initial'}>
                            <T>become_a_member</T>
                        </Typography>

                        {StoreReferral.HasReferral() && (
                            <Typography
                                mt="25px"
                                variant="caption"
                                fontWeight={600}
                                color="#0B3B61"
                                fontSize={'16px'}
                                textAlign={width < 700 ? 'center' : 'initial'}>
                                <span
                                    dangerouslySetInnerHTML={{
                                        __html: translate('subtitle_invited_you_to_member').replace(
                                            '{{referral_name}}',
                                            StoreTranslate.UseLanguage(StoreReferral.Customer()?.DisplayName())
                                        )
                                    }}
                                />
                            </Typography>
                        )}

                        <Typography
                            mt="15px"
                            variant="caption"
                            fontWeight={400}
                            color="#0B3B61"
                            fontSize={'16px'}
                            textAlign={width < 700 ? 'center' : 'initial'}>
                            <T>success_message</T>
                        </Typography>
                    </Stack>
                </Stack>
            </BoxModalStyled>
        </Modal>
    )
})

const SkeletonLoading = observer(() => {
    return (
        <StackSkeletonLoading mt="30px">
            <Stack flexDirection={storeElement.dynamicWidth < 700 ? 'column' : 'row'} gap={'20px'} justifyContent={'space-between'}>
                <Stack width={'100%'}>
                    <Skeleton variant="text" sx={{ fontSize: '30px', width: '40%' }} />
                    <Skeleton variant="rounded" height={50} />
                </Stack>
                <Stack width={'100%'}>
                    <Skeleton variant="text" sx={{ fontSize: '30px', width: '40%' }} />
                    <Skeleton variant="rounded" height={50} />
                </Stack>
            </Stack>
            <Stack flexDirection={storeElement.dynamicWidth < 700 ? 'column' : 'row'} gap={'20px'} justifyContent={'space-between'}>
                <Stack width={'100%'}>
                    <Skeleton variant="text" sx={{ fontSize: '30px', width: '40%' }} />
                    <Skeleton variant="rounded" height={50} />
                </Stack>
                <Stack width={'100%'}>
                    <Skeleton variant="text" sx={{ fontSize: '30px', width: '40%' }} />
                    <Skeleton variant="rounded" height={50} />
                </Stack>
            </Stack>
            <Stack flexDirection={storeElement.dynamicWidth < 700 ? 'column' : 'row'} gap={'20px'} justifyContent={'space-between'}>
                <Stack width={'100%'}>
                    <Skeleton variant="text" sx={{ fontSize: '30px', width: '40%' }} />
                    <Skeleton variant="rounded" height={50} />
                </Stack>
                <Stack width={'100%'}>
                    <Skeleton variant="text" sx={{ fontSize: '30px', width: '40%' }} />
                    <Skeleton variant="rounded" height={50} />
                </Stack>
            </Stack>
        </StackSkeletonLoading>
    )
})

const ThemeSelector = ({ countryCapitalize, children }) => {
    const [theme, setTheme] = useState()

    useLayoutEffect(() => {
        if (!theme) {
            const themeName = `${countryCapitalize}FormTheme`
            import(`../Forms/Theme/${themeName}`).then(module => {
                setTheme(module.FormTheme)
            })
        }
    }, [countryCapitalize, theme])

    return <>{theme ? <ThemeProvider theme={theme}>{children}</ThemeProvider> : null}</>
}

const StackAlreadyAccountStyled = styled(Stack)`
    align-items: center;
    background-color: #e9f1fa;
    border-radius: 12px;
    padding: 16px 0;

    @media screen and (min-width: 700px) {
        padding: 20px 30px;
        flex-direction: row;
        justify-content: space-between;
    }
`

const StackCreateAccountStyled = styled(Stack)`
    padding: 30px 20px;

    background: #ffffff;
    border-radius: 12px;
    align-items: center;
    position: relative;

    @media screen and (min-width: 700px) {
        padding: 50px 30px;
    }
`

const PaperStyled = styled(Paper)`
    padding-top: 20px !important;

    @media screen and (max-width: 699px) {
        .MuiBox-root {
            width: 100% !important;

            :nth-of-type(1),
            :nth-of-type(3),
            :nth-of-type(6) {
                margin-right: 0px !important;
            }

            :nth-of-type(6) {
                margin-bottom: 0px !important;
            }
        }
    }

    @media screen and (min-width: 700px) {
        padding-top: 30px !important;

        //     .MuiBox-root {
        //         :nth-of-type(1),
        //         :nth-of-type(3),
        //         :nth-of-type(5) {
        //             margin-right: 20px !important;
        //         }
        //         :nth-of-type(5) {
        //             width: calc(50% - 10px) !important;
        //             margin-bottom: 0px !important;
        //         }
        //         :nth-of-type(6) {
        //             margin-bottom: 0px !important;
        //             margin-right: 0px !important;
        //         }
        //     }
    }
`

const BoxModalStyled = styled(Box)`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 360px;
    background-color: #ffffff;
    border-radius: 8px;

    @media screen and (max-width: 430px) {
        width: 335px;
    }
`

const StackSkeletonLoading = styled(Stack)`
    @media screen and (min-width: 700px) {
        width: 600px;
    }

    @media screen and (min-width: 800px) {
        width: 700px;
    }
`
const StackAgreeAndVerify = styled(Stack)`
    align-items: center;
    row-gap: 20px;
    flex-direction: column;
    padding-top: 20px;

    & > div {
        width: 100%;
    }

    & > div:nth-of-type(2) {
        display: flex;
        justify-content: center;
    }

    @media screen and (min-width: 800px) {
        padding-top: 30px;
    }

    @media screen and (min-width: 1024px) {
        flex-direction: row;
        column-gap: 20px;
        & > div {
            width: 50%;
        }

        & > div:nth-of-type(2) {
            justify-content: start;
        }
    }
`
export default SignUp
