import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'
import { Box, Stack, styled } from '@mui/material'
import PersonalDetailsRoute from './Route'
import { useSizeWindow } from 'Helpers/GlobalHelpers'
import UserAccount from 'Components/Pages/UserAccount'
import Breadcrumb from '../UserAccountMenu/Breadcrumb'
import MobileLayoutWrapper from '../UserAccountMenu/MobileLayoutWrapper'

const SettingWrapper = observer(props => {
    const { width } = useSizeWindow()
    return width >= 900 ? (
        <UserAccount>
            <DesktopLayout>
                <PersonalDetailsRoute />
            </DesktopLayout>
        </UserAccount>
    ) : (
        <WrapperMobileLayout>
            <Box className="mobile-layout-box">
                <PersonalDetailsRoute />
            </Box>
        </WrapperMobileLayout>
    )
})

export const WrapperMobileLayout = observer(props => {
    return (
        <MobileLayoutWrapper>
            <StackMobileLayout>
                <Stack width={'100%'}>
                    <Breadcrumb />
                    {props.children}
                </Stack>
            </StackMobileLayout>
        </MobileLayoutWrapper>
    )
})

const StackMobileLayout = styled(Stack)`
    
    align-items: center;
    background: #d1d9e2 !important;
    display: flex;
    width: 100%;

    .mobile-layout-box {
        box-sizing: border-box;
        /* width: 300px; */
        width: 100%;
        padding: 30px 20px 40px 20px;
        background-color: #fff;
        box-shadow: 0px 1px 2px rgba(14, 35, 56, 0.2);
        border-radius: 12px;
    }

    @media screen and (min-width: 414px) {
        .mobile-layout-box {
            /* width: 384px; */
        }
    }
`
const DesktopLayout = styled('div')`
    box-sizing: border-box;
    width: 100%;
    padding: 32px;
    background-color: #fff;
    border-radius: 12px;
`

export default SettingWrapper
