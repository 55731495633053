import React, { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { Box, Stack, Typography, styled } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { storeElement } from 'GlobalStores/Cores/Elements/StoreElement'
import { runInAction } from 'mobx'
import { formatPriceDemical, useSizeWindow } from 'Helpers/GlobalHelpers'
import { T } from 'GlobalStores/Cores/Translator/useTranslate'
import moment from 'moment'

const OrderHistoryLists = observer(props => {
    const navigate = useNavigate()
    const { width } = useSizeWindow()

    useEffect(() => {
        runInAction(() => {
            storeElement.settingCard.titleAlign = 'center'
            storeElement.settingCard.padding = width > 834 ? '50px 30px 20px 30px' : '30px 20px 40px 20px'
        })

        return () => {
            runInAction(() => {
                storeElement.settingCard.titleAlign = ''
                storeElement.settingCard.padding = ''
            })
        }
    }, [])

    function toMonthName(monthNumber) {
        const date = new Date()
        date.setMonth(monthNumber - 1)

        return date.toLocaleString('en-US', {
            month: 'short'
        })
    }

    // const dateItem = props.item.dateCreated.split('-')[2].slice(0, 2)
    // const monthItem = props.item.dateCreated.split('-')[1]
    // const yearItem = props.item.dateCreated.split('-')[0]

    const date = new Date(props.item.dateCreated)
    const year = date.getFullYear()
    const month = date.getMonth() + 1 // Months are zero-based, so we add 1
    const day = date.getDate()
    const getNameMonth = toMonthName(month)

    return (
        <>
            <BoxStyled>
                <StackStyled margin={'20px 30px 0 30px'}>
                    <Typography variant="h4" lineHeight="24px" fontWeight={'600'} color="#003B6F">
                        {`${day} ${getNameMonth} ${year}`}
                    </Typography>
                    <Typography variant="h4" lineHeight="24px" fontWeight={'600'} color="#003B6F">
                        {formatPriceDemical(props.item.terms.total)} {props.item.currency}
                    </Typography>
                </StackStyled>

                <StackStyled margin={'0 30px 20px 30px'}>
                    <Typography mt="15px" variant="caption" fontWeight={300} color="#6687A2" lineHeight="10px">
                        <T>order</T>#&nbsp;{props.item.id.unicity.split('-')[1]}
                    </Typography>
                    <Typography
                        mt="15px"
                        variant="link"
                        lineHeight="10px"
                        color="#079FF4"
                        onClick={() => navigate(`/user/orderhistory/${props.item.id.unicity.split('-')[1]}`)}>
                        <T>details</T>
                    </Typography>
                </StackStyled>
            </BoxStyled>
        </>
    )
})

const BoxStyled = styled(Box)`
    width: 100%;
    height: 89px;
    background-color: #ecf2f9;
    border-radius: 6px;

    :not(:first-of-type) {
        margin-top: 20px;
    }
`

const StackStyled = styled(Stack)`
    flex-direction: row;
    justify-content: space-between;
`

export default OrderHistoryLists
