import { ObjectHelpers } from 'Helpers/ObjectHelpers'
import { get, last } from 'lodash'
import { makeAutoObservable, reaction, runInAction, toJS } from 'mobx'
import { Paths } from 'Routers/Paths'
import StoreCountry from '../County/StoreCountry'
import StoreLoader from '../Elements/StoreLoader'
import { getDictionary } from './Services'
class Translator {
    fetchOnce = false
    currentLanguage = ''
    currentPage = ''

    dictionaries = {}
    loading = false

    dictionaryStaticComponent = {}

    lists = {}

    Dictionaries() {
        const page = getDictionaryPage()
        if (this.currentPage !== page) {
            runInAction(() => {
                this.currentPage = page
            })
        }

        if (this.fetchOnce === false) {
            if (this.lists[this.currentPage]) {
                runInAction(() => {
                    this.dictionaries = this.lists[this.currentPage]
                })
            } else {
                if (!this.lists[page] && this.loading === false) {
                    this.loading = true
                    this.getTranslationByPage()
                }
            }
        }

        return this.dictionaries
    }

    CurrentLanguage() {
        return this.currentLanguage
    }

    SetCurrentLanguage(value) {
        runInAction(() => {
            // console.log("some want to show this lang ", toJS(value))
            localStorage.setItem('currentLanguage', value)
            this.currentLanguage = value
        })
    }

    async getTranslationByPage() {
        if (this.fetchOnce === false) {
            try {
                runInAction(() => {
                    this.loading = true
                })
                let getPage = `feelgreat/${this.currentPage}`
                let dictionaries = await getDictionary(StoreCountry.Country3(), getPage)
                runInAction(() => {
                    this.dictionaries = dictionaries
                    this.lists[this.currentPage] = this.dictionaries
                    this.loading = false
                })
            } catch (e) {
                runInAction(() => {
                    this.loading = false
                })
            }
        }
    }

    async getMultiplesTranslations() {
        StoreLoader.mainApiLoaded['dictionary'] = false
        const pages = ['login', 'checkout', 'referral']

        try {
            runInAction(() => {
                StoreLoader.dictionary = false
            })

            const promises = pages.map(page => {
                return getDictionary(StoreCountry.Country3(), `feelgreat/${page}`)
            })

            const dictionaries = await Promise.all(promises)

            runInAction(() => {
                pages.forEach((page, index) => {
                    this.lists[page] = dictionaries[index]
                })
                this.loading = false
                StoreLoader.dictionary = true
                StoreLoader.mainApiLoaded['dictionary'] = true
            })
        } catch (e) {}
    }

    async getWholeTranslations() {
        runInAction(() => {
            StoreLoader.mainApiLoaded['dictionary'] = false
            this.fetchOnce = true
            StoreLoader.dictionary = false
        })
        try {
            const dictionaries = await getDictionary(StoreCountry.Country3(), `feelgreat`)
            runInAction(() => {
                this.lists = dictionaries
                this.loading = false
                StoreLoader.dictionary = true
                StoreLoader.mainApiLoaded['dictionary'] = true
            })
        } catch (e) {}
    }

    async FetchDictionaryStaticComponent() {
        if (ObjectHelpers.isEmpty(this.dictionaryStaticComponent) && this.fetchOnce === false) {
            const translated = await getDictionary(StoreCountry.Country3(), 'feelgreat/staticComponents')
            runInAction(() => {
                this.dictionaryStaticComponent = translated
            })
        }
    }

    GetDictionaryStaticComponents() {
        return this.dictionaryStaticComponent
    }

    constructor() {
        const page = getDictionaryPage()
        runInAction(() => {
            this.currentPage = page
        })
        this.getWholeTranslations()

        makeAutoObservable(this)
    }
}

class StoreTranslator {
    instance = null
    /**
     * @returns {Translator} Translator
     */
    Instance() {
        if (this.instance === null) {
            this.instance = new Translator()
        }

        return this.instance
    }

    Dictionaries() {
        return this.Instance().Dictionaries()
    }

    CurrentLanguage() {
        return this.Instance().CurrentLanguage()
    }

    SetCurrentLanguage(value) {
        this.Instance().SetCurrentLanguage(value)
    }

    IsEnglish() {
        return this.Instance().CurrentLanguage() === 'EN'
    }

    UseLanguage(content) {
        if (content) {
            if (this.CurrentLanguage() === 'EN') {
                return content.english
            } else {
                if (content.native) {
                    return content.native
                } else {
                    return content.english
                }
            }
        }

        return ''
    }

    GetDictionaryStaticComponents(key) {
        return this.Instance().GetDictionaryStaticComponents()
    }

    InitMultiples() {}

    constructor() {
        makeAutoObservable(this)
    }
}

function getDictionaryPage() {
    let page = last(window.location.pathname.split('/'))

    const foundPageRegx = pageMapperList.find(rx => window.location.pathname.match(rx))

    if (PageMapper[page]) {
        page = PageMapper[page]
    } else if (foundPageRegx) {
        const [matchedPage] = window.location.pathname.match(foundPageRegx)
        page = matchedPage
    } else if (Paths.includes(page)) {
        page = page
    } else {
        page = 'landing'
    }
    return page
}

const PageMapper = {
    checkout: 'order_detail_2022',
    ordersummary: 'summary',
    success: 'paymentResponse',
    failed: 'paymentResponse',
    referral: 'referral'
}

const pageMapperList = [
    /centurion/,
    /user/,
    /checkout/,
    /login/,
    /register-new-ba/,
    /new-password-error/,
    /new-password-success/,
    /new-password/,
    /sign-up/,
    /contact-us/
]

export default StoreTranslator = new StoreTranslator()
