import { Box, styled, Typography } from '@mui/material'
import Radio from '@mui/joy/Radio'
import RadioGroup from '@mui/joy/RadioGroup'
import { StyledEngineProvider, CssVarsProvider } from '@mui/joy/styles'
import { observer } from 'mobx-react-lite'

const DefaultRadio = observer(
    ({ id = 'filled-error-helper-text', label = '', value = 'email', fullWidth = true, onChange, paddingleft = 16, name = '', option = [] }) => {
        return (
            <Box width={fullWidth ? '100%' : 'auto'}>
                <div style={{ marginBottom: '8px' }}>
                    <label style={{ paddingLeft: paddingleft }}>
                        <DefaultLabelTypo variant="label">{label}</DefaultLabelTypo>
                    </label>
                </div>
                <StyledEngineProvider injectFirst>
                    <CssVarsProvider>
                        <RadioGroup
                            row
                            id={id}
                            aria-labelledby="segmented-controls-example"
                            name={name}
                            value={value}
                            onChange={onChange}
                            sx={{
                                minHeight: 36,
                                // padding: '4px',
                                color: '#003764',
                                // borderRadius: '8px',
                                border: '1px solid rgba(11, 59, 97, 0.15)',
                                '--RadioGroup-gap': '4px',
                                '--Radio-action-radius': '8px'
                            }}>
                            {option.map(item => (
                                <Radio
                                    key={`radio_${item.value}`}
                                    value={item.value}
                                    disableIcon
                                    label={item.title}
                                    variant="plain"
                                    sx={{
                                        px: 2,
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        width: '100%',
                                        color: '#003764',
                                        fontWeight: item.value === value ? 600 : 400,
                                        '&:hover': {
                                            bgcolor: 'rgba(0, 55, 100, 0.1)',
                                            borderRadius: '8px'
                                        },
                                        '.JoyRadio-label': {
                                            textAlign: 'center',
                                            fontSize: '14px'
                                        }
                                    }}
                                    slotProps={{
                                        action: ({ checked }) => ({
                                            sx: {
                                                ...(checked && {
                                                    // bgcolor: 'rgba(0, 55, 100, 0.1)',
                                                    bgcolor: '#FFBF3A',

                                                    fontWeight: 600,
                                                    '&:hover': {
                                                        bgcolor: '#FFBF3A'
                                                        // bgcolor: 'rgba(0, 55, 100, 0.1)'
                                                    }
                                                })
                                            }
                                        })
                                    }}
                                />
                            ))}
                        </RadioGroup>
                    </CssVarsProvider>
                </StyledEngineProvider>
            </Box>
        )
    }
)

const DefaultLabelTypo = styled(Typography)(({ theme }) => ({
    fontFamily: 'Inter',
    fontStyle: 'normal',
    color: theme?.typography?.label?.color || '#153862',
    fontWeight: theme?.typography?.label?.fontWeight || 400
}))

export default DefaultRadio
