import axios from 'axios'
import { ServerEnv } from 'Configs/ServerEnv'
import HttpHelper from 'Services/HttpHelper'

const HydraOrderTerms = (data, token) => {
    const url = HttpHelper.Proxy({ originalUrl: `${ServerEnv.Hydra()}/orderTerms` })

    return axios.post(url, data, {
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: `Bearer ${token}`
        }
    })
}

export const HydraAPIs = {
    HydraOrderTerms
}