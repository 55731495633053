import { Card, CardContent, styled, ThemeProvider, Typography } from '@mui/material'
import { MainTemplate } from 'Components/Template/MainTemplate'
import { observer } from 'mobx-react-lite'
import { ReactComponent as Phone } from './Assets/phone.svg'
import { ReactComponent as Mail } from './Assets/mail.svg'
import { Box } from '@mui/system'
import { useSizeWindow } from 'Helpers/GlobalHelpers'
import StoreFeelGreat from 'GlobalStores/FeelGreat/StoreFeelGreat'
import { get } from 'lodash'
import { T, useTranslate } from 'GlobalStores/Cores/Translator/useTranslate'

const QuestionCard = observer(props => { 
    const phone = get(StoreFeelGreat, 'contents.contact.phone', '')
    const email = get(StoreFeelGreat, 'contents.contact.email', '')
    const { width } = useSizeWindow()
    return (
        <Card>
            <CardContent style={{ padding: width < 600 ? '30px 20px' : 30 }}>
                <Typography
                    variant="h3"
                    sx={{
                        marginBottom: '25px',
                        lineHeight: '32px'
                    }}>
                    <T>questions_about_your_order</T>
                </Typography>

                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: {
                            xs: 'column',
                            sm: 'row',
                            md: 'column'
                        },
                        width: '100%',
                        flex: '1'
                    }}>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '15px',
                            width: {
                                xs: '100%',
                                sm: '50%',
                                md: '100%'
                            }
                        }}>
                        <Phone />
                        <Typography
                            variant="caption"
                            sx={{
                                color: '#003764',
                                cursor: 'pointer'
                            }}
                            onClick={() => window.open(`tel:${phone}`, '_self')}>
                            {phone}
                        </Typography>
                    </Box>

                    <Box
                        sx={{
                            display: {
                                sm: 'none',
                                md: 'block'
                            }
                        }}>
                        <hr
                            color="D1D9E2"
                            size="1"
                            style={{
                                marginTop: '23px',
                                marginBottom: '23px'
                            }}
                        />
                    </Box>

                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '15px',
                            width: {
                                xs: '100%',
                                sm: '50%',
                                md: '100%'
                            }
                        }}>
                        <Mail />
                        <Typography
                            variant="caption"
                            sx={{
                                color: '#003764',
                                cursor: 'pointer'
                            }}
                            onClick={() => window.open(`mailto:${email}`, '_self')}>
                            {email}
                        </Typography>
                    </Box>
                </Box>
            </CardContent>
        </Card>
    )
})

export default QuestionCard
