import { MainTemplate } from 'Components/Template/MainTemplate'
import { Box, Container, Skeleton, Stack, ThemeProvider } from '@mui/material'
import { observer } from 'mobx-react-lite'
import styled from '@emotion/styled'
import ReferCard from 'Components/Elements/PaymentResponse/ReferCard'
import QuestionCard from 'Components/Elements/PaymentResponse/QuestionCard'
import ThankyouCard from 'Components/Elements/PaymentResponse/ThankyouCard'
import GuideCard from 'Components/Elements/PaymentResponse/GuideCard'
import { useEffect } from 'react'
import { FeelGreatAPI } from 'Services/FeelGreat/APIs'
import { useLocation } from 'react-router-dom'
import { useState } from 'react'
import storeCountry from 'GlobalStores/Cores/County/StoreCountry'
import storeReferral from 'GlobalStores/Referral/StoreReferral'
import trackingEvents from 'Services/Tracker/TrackingEvents'
import storeAuth from 'GlobalStores/User/StoreAuth'
import { PaymentAPIs } from 'GlobalStores/Checkout/PaymentServices/PaymentAPI'
import { get } from 'lodash'

const reDirectPaymentCountry = ['PH', 'TW', 'HK', 'TR', 'SG']
const reDirect3dsPaymentCountry = ['MA', 'JO', 'EG']

const PaymentResponseSuccess = observer(() => {
    const location = useLocation()

    const [states, setStates] = useState({
        paymentId: '',
        orderSummary: '',
        email: '',
        orderNumber: '',
        ba_id: '',
        referral_code: '',
        baStatus: ''
    })

    const [loading, setLoading] = useState(true)

    useEffect(() => {
        if (reDirectPaymentCountry.includes(storeCountry.Country2())) {
            GetGatewayPaymentDetail()
        } else if (reDirect3dsPaymentCountry.includes(storeCountry.Country2())) {
            GetHydra3DSPaymentDetail()
        } else {
            GetPaymentDetail()
        }
    }, [])

    // Default
    const GetPaymentDetail = async () => {
        try {
            setLoading(true)
            let paymentId = new URLSearchParams(location.search).get('payment_id')
            const response = await FeelGreatAPI.GetPaymentDetail(paymentId)
    
            setStates({
                ...states,
                paymentId: paymentId,
                email: response.data.email,
                orderNumber: response.data.order_number,
                orderSummary: `${response.data.total_price.value} ${response.data.total_price.suffix}`,
                baStatus: get(response.data, 'forEmailJourney.dist_status')
            })
            setLoading(false)
        } catch (e) {
            console.error(e)
            setLoading(false)
        }
    }

    // Gateway payment
    const GetGatewayPaymentDetail = async () => {
        try {
            setLoading(true)
            let gatewayRefId = new URLSearchParams(location.search).get('gateway_ref_id')
            const response = await FeelGreatAPI.GatewayPaymentDetail(gatewayRefId) 
            
            setStates({
                ...states,
                email: response.data.email,
                ba_id: response.data.ba_id,
                referral_code: response.data.referral_code,
                orderNumber: !!response.data.order_number ? response.data.order_number : gatewayRefId
            })

            storeReferral.referralCode = response.data.referral_code

            if (storeCountry.Country2() === 'PH') {
                trackingEvents.PurchaseEvent({
                    transaction_id: response.data.forGa?.transaction_id,
                    value: response.data.forGa?.value,
                    currency: response.data.forGa?.currency,
                    category: response.data.forGa?.category,
                    items: response.data.forGa?.items
                })
            }

            setLoading(false)
        } catch (e) {
            console.error(e)
            setLoading(false)
        }
    }

    // 3ds hydra payment
    const GetHydra3DSPaymentDetail = async () => {
        try {
            setLoading(true)
            let paymentId = new URLSearchParams(location.search).get('payment_id')
            let baId = storeAuth.GetId() || undefined
            const response = await FeelGreatAPI.GetPaymentDetail(paymentId, baId)
            setStates({
                ...states,
                paymentId: paymentId,
                email: response.data.email,
                orderSummary: `${response.data.total_price.value} ${response.data.total_price.suffix}`,
                orderNumber: response.data.order_number
            })

            await PaymentAPIs.UpdateLogByOrder({
                payments_id: paymentId,
                payment_status: 'success',
                order_number: response.data.order_number
            })

            let orderDetail = await PaymentAPIs.GetOrderDetailByOrderNumber(response.data.order_number)

            if (orderDetail.status === 200) {
                await storeAuth.AutoLogin(orderDetail.data.logonAs.id)
            }

            setLoading(false)
        } catch (e) {
            console.error(e)
            setLoading(false)
        }
    }

    return (
        <ThemeProvider theme={MainTemplate}>
            <Stack backgroundColor={MainTemplate.palette.background.default} minHeight="100vh">
                {loading === false ? (
                    <ContainerStyled maxWidth="lg">
                        <Stack sx={{ justifyContent: 'center', flexDirection: 'row' }}>
                            {/* Desktop */}
                            {states.baStatus === 'H' ? (
                                <StackStyledHDesktop>
                                    <ThankyouCard states={states} />
                                    <GuideCard />
                                    <QuestionCard />
                                </StackStyledHDesktop>
                            ) : (
                                <StackStyledDesktop>
                                    <CardWrapper sx={{ gap: '30px', display: 'grid' }}>
                                        <ThankyouCard states={states} />
                                        <QuestionCard />
                                    </CardWrapper>
                                    <CardWrapper sx={{ gap: '30px' }}>
                                        <GuideCard />
                                        <ReferCard states={states} />
                                    </CardWrapper>
                                </StackStyledDesktop>
                            )}

                            {/* Mobile */}
                            <StackStyledMobile>
                                <Stack sx={{ gap: '30px' }}>
                                    <ThankyouCard states={states} redirectPaymentCountry={reDirectPaymentCountry} />
                                    <GuideCard />
                                </Stack>
                                <Stack sx={{ gap: '30px' }}>
                                    <QuestionCard />
                                    {states.baStatus === 'H' ? <></> : <ReferCard states={states} />}
                                </Stack>
                            </StackStyledMobile>
                        </Stack>
                    </ContainerStyled>
                ) : (
                    <>
                        {/* <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 'calc(100vh - 138px)', boxSizing: 'border-box' }}>
                            <CircularProgress />
                        </Box> */}
                        <PageSkeletonFormElement />
                    </>
                )}
            </Stack>
        </ThemeProvider>
    )
})

const PageSkeletonFormElement = () => (
    <>
        <Stack minHeight="100vh">
            <ContainerStyled maxWidth="lg">
                <Stack sx={{ justifyContent: 'center', flexDirection: 'row' }}>
                    <SkeletonStackStyled sx={{ width: '100%', gap: { xs: '30px' } }}>
                        <Stack sx={{ gap: '30px' }}>
                            <Skeleton
                                variant="rounded"
                                width={'100%'}
                                sx={{ margin: '0', borderRadius: '12px', height: { xs: '207px', sm: '230px', md: '242px' } }}
                            />
                            <Skeleton
                                variant="rounded"
                                width={'100%'}
                                sx={{ margin: '0', borderRadius: '12px', height: { xs: '482px', sm: '312px', md: '285px', lg: '402px' } }}></Skeleton>
                        </Stack>
                        <Stack sx={{ gap: '30px' }}>
                            <Skeleton variant="rounded" width={'100%'} sx={{ margin: '0', borderRadius: '12px' }}>
                                <QuestionCard />
                            </Skeleton>
                            <Skeleton
                                variant="rounded"
                                width={'100%'}
                                sx={{ margin: '0', borderRadius: '12px', height: { xs: '396px', sm: '370px', md: '338px', lg: '402px' } }}></Skeleton>
                        </Stack>
                    </SkeletonStackStyled>
                </Stack>
            </ContainerStyled>
        </Stack>
    </>
)

const SkeletonStackStyled = styled(Stack)`
    padding-top: 30px;
    min-height: 50vh;
    @media screen and (min-width: 1200px) {
        display: grid;
        grid-template-columns: 770px 370px;
        gap: 30px;
    }
`

const StackStyledDesktop = styled(Stack)`
    padding-top: 30px;
    min-height: 50vh;
    gap: 30px;
    display: none;
    @media screen and (min-width: 1200px) {
        display: flex;
    }
`

const StackStyledHDesktop = styled(Stack)`
    padding-top: 30px;
    min-height: 50vh;
    display: none;
    margin-bottom: 30px;
    @media screen and (min-width: 1200px) {
        display: grid;
        grid-template-columns: 770px;
        gap: 30px;
    }
`

const StackStyledMobile = styled(Stack)`
    padding-top: 30px;
    min-height: 50vh;
    gap: 30px;
    margin-bottom: 30px;
    @media screen and (min-width: 1200px) {
        display: none;
    }
`

const CardWrapper = styled(Box)`
    display: grid;
    grid-template-columns: 770px 370px;
`

const ContainerStyled = styled(Container)`
    @media screen and (min-width: 730px) and (max-width: 1200px) {
        padding: 0 70px;
    }
`

export default PaymentResponseSuccess