import { Stack, Typography, styled } from '@mui/material'
import { observer } from 'mobx-react-lite'

const BoxSelections = observer(props => {
    return (
        <Stack spacing="12px">
            <BoxSelectionTitle>{props.title}</BoxSelectionTitle>
            <Stack flexDirection={props.fullwidth ? 'column' : 'row'} gap={props.gap !== 'default' ? props.gap : props.fullwidth ? '16px' : '30px'}>
                {props.children}
            </Stack>
        </Stack>
    )
})

const BoxSelectionTitle = styled(Typography)`
    font-family: Poppins;
    font-style: normal;
    font-weight: 600 !important;
    font-size: 12px !important;
    color: #a4aebe;
`

export default BoxSelections
