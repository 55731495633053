import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { Stack, Typography, Box, styled } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import LogoPayment from '../LogoPayment/LogoPayment'
import CustomDialogV2 from './Dialog/CustomDialogV2'
import { T, useTranslate } from 'GlobalStores/Cores/Translator/useTranslate'
import StoreTranslate from 'GlobalStores/Cores/Translator/StoreTranslate'
import { useEffect } from 'react'
import { FeelGreatAPI } from 'Services/FeelGreat/APIs'
import { runInAction } from 'mobx'
import { ReactComponent as SpinnerIcon } from './Assets/button-icon-spinner.svg'
import StoreSubScriptions from 'GlobalStores/StoreSubScriptions'
import { NumberHelpers } from 'Helpers/NumberHelpers'
import storeCountry from 'GlobalStores/Cores/County/StoreCountry'
import storeFeelGreat from 'GlobalStores/FeelGreat/StoreFeelGreat'

const ItemSubscriptions = observer(props => {
    return (
        <>
            <Stack gap="20px" width="100%">
                {props.order.map((items, k) => (
                    <ItemProducts key={k} products={items} payment={props.payment} getOrder={props.getOrder} />
                ))}
            </Stack>
        </>
    )
})

const ItemProducts = observer(({ products, payment, getOrder }) => {
    const [loading, setLoading] = useState(false)
    const [openDialog, setOpenDialog] = useState(false)
    const [typeDialog, setTypeDialog] = useState('')
    const [flavorQTY, setFlavorQTY] = useState([])
    const [address, setAddress] = useState('')
    const [skipDate, setSkipDate] = useState('')

    const navigate = useNavigate()
    const translate = useTranslate()

    const isShowEditSubscription = (key = '') => {
        if (!storeFeelGreat.GetSubscriptionConfigData('settings.isEnable')) {
            return !['JPN'].includes(storeCountry.Country3())
        }

        return storeFeelGreat.GetSubscriptionConfigData(`${key}.isEnable`)
    }

    const nextShipDate = new Date(products?.recurrence?.dateStarts).toLocaleDateString('en-uk', { year: 'numeric', month: 'short', day: 'numeric' })
    const creditCardNetwork = payment[payment.length - 1]?.methodDetails?.creditCardNetwork
    const creditCardNumber = products?.transactions?.items[0].methodDetails?.alias
        ? String(products?.transactions?.items[0].methodDetails?.alias).slice(-4)
        : 'xxxx'
    const OrderTotal = products?.terms?.total
    const shipping = products?.terms?.freight?.amount === 0 ? 'Free' : products?.terms?.freight?.amount
    const currency = products?.currency
    const dateOfmonth = products?.recurrence?.schedule?.dayOfMonth
    const subscriptionID = products?.id?.unicity
    const dateCreated = products.dateCreated
    const lastRunDate = products?.recurrence?.lastRunDate
    const lastOrder = products?.recurrence?.lastOrderId?.unicity
    const { shipToAddress } = products

    const isEnableSetting = storeFeelGreat.GetSubscriptionConfigData('settings.isEnable')
    const isEnableSendNow = storeFeelGreat.GetSubscriptionConfigData('sendNow.isEnable')
    const isEnableSkipNextDelivery = storeFeelGreat.GetSubscriptionConfigData('skipNextDelivery.isEnable')

    useEffect(() => {
        const date = addMonths(products?.recurrence?.dateStarts, 1)
        const skipDate = new Date(date).toLocaleDateString('en-uk', { year: 'numeric', month: 'short', day: 'numeric' })
        setSkipDate(skipDate)

        const formatAndSetAddress = async () => {
            try {
                const newData = {
                    shipment_options: 'delivery',
                    shipToAddress: JSON.stringify(shipToAddress)
                }
                const res = await FeelGreatAPI.FormatAddress(newData)
                if (res.data.success) {
                    setAddress(res.data.display_address)
                }
            } catch (error) {
                console.log(error)
            }
        }

        formatAndSetAddress()
    }, [shipToAddress, products?.recurrence?.dateStarts])

    useEffect(() => {
        runInAction(() => {
            const newData = products.productItem.map(item => {
                return {
                    qty: item.quantity,
                    item_code: item.item_code
                }
            })
            setFlavorQTY(newData)
        })

        return () => {
            runInAction(() => {
                setFlavorQTY([])
            })
        }
    }, [products.productItem])

    const handleOpenDialog = type => {
        setOpenDialog(true)
        setTypeDialog(type)
    }

    const handleCancel = () => {
        setLoading(true)
        StoreSubScriptions.SetSubscriptionsList(products)
        setTimeout(() => {
            setLoading(false)
            navigate('/user/subscriptions/cancel-subscriptions')
        }, 1000)
    }

    const generateDaysList = daysInCurrentMonth => {
        let suffix

        switch (daysInCurrentMonth) {
            case 1:
                suffix = 'st'
                break
            case 2:
                suffix = 'nd'
                break
            case 3:
                suffix = 'rd'
                break
            default:
                suffix = 'th'
        }

        return `${daysInCurrentMonth}${suffix} ${translate('of_th_month')}`
    }

    const addMonths = (date, months) => {
        const outputDate = new Date(date)
        const d = outputDate.getDate()

        outputDate.setMonth(outputDate.getMonth() + +months)
        if (outputDate.getDate() !== d) {
            outputDate.setDate(0)
        }

        return outputDate
    }

    const getAlignmentCondition = () => {
        if (!isEnableSetting) return 'space-between'

        if (isEnableSendNow && isEnableSkipNextDelivery) {
            return 'space-between'
        } else if (isEnableSendNow && !isEnableSkipNextDelivery) {
            return 'flex-start'
        } else if (!isEnableSendNow && isEnableSkipNextDelivery) {
            return 'flex-end'
        } else {
            return 'flex-start'
        }
    }

    const getButtonTitle = (key = '') => {
        return StoreTranslate.UseLanguage(storeFeelGreat.GetSubscriptionConfigData(`${key}.text`) || { english: '', native: '' })
    }

    return (
        <Card>
            {loading && <CardDisable />}
            <Box bgcolor="#ffffff" borderRadius="12px 12px 0px 0px" padding="20px 40px">
                {products.productItem.map((item, index) => {
                    return (
                        <Box key={index}>
                            <Stack flexDirection="row" justifyContent="space-between" padding="20px 0px">
                                <Stack gap="20px" flexDirection="row">
                                    <img src={item?.image_url} alt={item?.image_url} width={60} height={60} />

                                    <Stack gap="13px">
                                        <Typography variant="title">
                                            {item?.quantity} x {StoreTranslate.UseLanguage(item?.flavor?.description)}
                                        </Typography>
                                        <Typography variant="caption4">{StoreTranslate.UseLanguage(item?.flavor?.text)}</Typography>
                                    </Stack>
                                </Stack>

                                {isShowEditSubscription('changeFlavor') && (
                                    <Stack flexDirection="row" justifyContent="end" height="100%">
                                        {index === 0 && (
                                            <Typography variant="link" textAlign="right" color="#079FF4" onClick={() => handleOpenDialog('change_flavor')}>
                                                {isEnableSetting ? getButtonTitle('changeFlavor', <T>change</T>) : <T>change</T>}
                                            </Typography>
                                        )}
                                    </Stack>
                                )}
                            </Stack>
                            {index === products.productItem.length - 1 ? '' : <Box borderBottom="1px solid #CCDEEF" />}
                        </Box>
                    )
                })}
            </Box>

            <Box padding="0px 40px" bgcolor="#fdeded" sx={{ borderRadius: isShowEditSubscription('cancelSubscription') ? '' : '0px 0px 12px 12px' }}>
                <Stack borderBottom="1px solid #CCDEEF" padding="30px 0px" gap="10px">
                    <Stack flexDirection="row" justifyContent="space-between" mb="10px">
                        <Typography variant="title" color="#153862">
                            <T>fg_subscription_id</T>
                        </Typography>
                        <Typography variant="caption4" textAlign="right" color="#003B6F">
                            {subscriptionID}
                        </Typography>
                    </Stack>

                    <Stack flexDirection="row" justifyContent="space-between">
                        <Typography variant="caption4" color="#003B6F">
                            <T>fg_date_created</T>
                        </Typography>
                        <Typography variant="caption4" textAlign="right" color="#003B6F">
                            {dateCreated}
                        </Typography>
                    </Stack>

                    <Stack flexDirection="row" justifyContent="space-between">
                        <Typography variant="caption4" color="#003B6F">
                            <T>fg_last_run_date</T>
                        </Typography>
                        <Typography variant="caption4" textAlign="right" color="#003B6F">
                            {lastRunDate ? lastRunDate : '-'}
                        </Typography>
                    </Stack>

                    <Stack flexDirection="row" justifyContent="space-between">
                        <Typography variant="caption4" color="#003B6F">
                            <T>fg_last_order</T>
                        </Typography>
                        <Typography variant="caption4" textAlign="right" color="#003B6F">
                            {lastOrder ? lastOrder : '-'}
                        </Typography>
                    </Stack>
                    <Stack flexDirection="row" justifyContent={getAlignmentCondition()} mt="10px">
                        {isShowEditSubscription('sendNow') && (
                            <Typography variant="link" color="#079FF4" onClick={() => handleOpenDialog('send_now')}>
                                {isEnableSetting ? getButtonTitle('sendNow', <T>send_now</T>) : <T>send_now</T>}
                            </Typography>
                        )}
                        {isShowEditSubscription('skipNextDelivery') && (
                            <Typography variant="link" color="#079FF4" onClick={() => handleOpenDialog('skip_next_delivery')} sx={{ alignSelf: 'flex-end' }}>
                                {isEnableSetting ? getButtonTitle('skipNextDelivery', <T>skip_next_delivery_button</T>) : <T>skip_next_delivery_button</T>}
                            </Typography>
                        )}
                    </Stack>
                </Stack>

                <Box borderBottom="1px solid #CCDEEF" padding="30px 0px">
                    <Stack flexDirection="row" justifyContent="space-between">
                        <Typography variant="title" color="#153862">
                            <T>subscription_dialog_header</T>
                        </Typography>
                        {isShowEditSubscription('changeAutoShipDate') && (
                            <Typography variant="link" textAlign="right" color="#079FF4" onClick={() => handleOpenDialog('change_date')}>
                                {isEnableSetting ? getButtonTitle('changeAutoShipDate', <T>change</T>) : <T>change</T>}
                            </Typography>
                        )}
                    </Stack>

                    <Stack mt="20px" gap="10px">
                        <Typography variant="caption4" color="#003B6F">
                            {translate('ship_date_detail').replace('{{date}}', generateDaysList(dateOfmonth))}
                        </Typography>
                        <Typography variant="caption4" color="#003B6F">
                            <T>next_ship_date</T>: {nextShipDate}
                        </Typography>
                    </Stack>
                </Box>

                <Box borderBottom="1px solid #CCDEEF" padding="30px 0px">
                    <Stack flexDirection="row" justifyContent="space-between">
                        <Typography variant="title" color="#153862">
                            <T>ship_to</T>:
                        </Typography>
                        {isShowEditSubscription('changeShipToAddress') && (
                            <Typography variant="link" textAlign="right" color="#079FF4" onClick={() => handleOpenDialog('change_address')}>
                                {isEnableSetting ? getButtonTitle('changeShipToAddress', <T>change</T>) : <T>change</T>}
                            </Typography>
                        )}
                    </Stack>
                    <Stack flexDirection="row" justifyContent="space-between" mt="20px">
                        <Typography variant="caption4" color="#003B6F">
                            {products.shipToName.fullName}
                        </Typography>
                        <Typography variant="caption4" textAlign="right" color="#003B6F" width="50%">
                            {address}
                        </Typography>
                    </Stack>
                </Box>

                <Stack padding="30px 0px" gap="20px">
                    <Stack flexDirection="row" justifyContent="space-between">
                        <Typography variant="title" color="#153862">
                            <T>payment</T>:
                        </Typography>
                        {isShowEditSubscription('changeCreditCard') && (
                            <Typography variant="link" textAlign="right" color="#079FF4" onClick={() => handleOpenDialog('change_payment')}>
                                {isEnableSetting ? getButtonTitle('changeCreditCard', <T>change</T>) : <T>change</T>}
                            </Typography>
                        )}
                    </Stack>
                    <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
                        <LogoPayment type={creditCardNetwork} />
                        <Typography variant="caption" textAlign="right" color="#003B6F">
                            xxxx - xxxx - xxxx - {creditCardNumber}
                        </Typography>
                    </Stack>
                    <Stack flexDirection="row" justifyContent="space-between">
                        <Typography variant="title" color="#153862" textAlign="left">
                            <T>order_total</T>
                        </Typography>
                        <Typography variant="title" color="#153862" textAlign="right">
                            {NumberHelpers.currency({
                                currency: storeCountry.GetCurrencyCode(),
                                number: OrderTotal,
                                symbol: storeCountry.GetCurrencySymbol()
                            })}
                        </Typography>
                    </Stack>
                    <Stack flexDirection="row" justifyContent="space-between">
                        <Typography variant="caption4" textAlign="left" color="#003B6F">
                            <T>shipping</T>
                        </Typography>
                        <Typography variant="caption4" textAlign="right" color="#003B6F">
                            {shipping}
                        </Typography>
                    </Stack>
                </Stack>
            </Box>

            {isShowEditSubscription('cancelSubscription') && (
                <Box bgcolor="#ffffff" borderRadius="0 0 12px 12px" padding="30px 40px">
                    <Stack flexDirection="row" justifyContent="center" gap="20px">
                        {loading ? (
                            <ButtonIconSpinner />
                        ) : (
                            <Typography variant="link" color="#079FF4" onClick={() => handleCancel()}>
                                {isEnableSetting ? getButtonTitle('cancelSubscription', <T>cancel_subscription</T>) : <T>cancel_subscription</T>}
                            </Typography>
                        )}
                    </Stack>
                </Box>
            )}

            <CustomDialogV2
                openDialog={openDialog}
                setOpenDialog={setOpenDialog}
                type={typeDialog}
                getOrder={getOrder}
                products={products}
                dateOfmonth={dateOfmonth}
                flavorQTY={flavorQTY}
                skipDate={skipDate}
            />
        </Card>
    )
})

const CardDisable = styled(Box)`
    position: absolute;
    width: 100%;
    height: 100%;
    background: #ffffff;
    opacity: 65%;
    border-radius: 12px;
    z-index: 1;
`

const Card = styled(Box)`
    border-radius: 12px;
    position: relative;
    width: 100%;
    box-shadow: 0px 1px 2px rgba(14, 35, 56, 0.2);
`

const ButtonIconSpinner = styled(SpinnerIcon)`
    animation-name: spin;
    animation-duration: 1000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;

    circle {
        stroke: #079ff4;
    }
    path {
        stroke: #c7ddfb;
    }

    @keyframes spin {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }
`

export default ItemSubscriptions
