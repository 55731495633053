import { Box, Skeleton, Stack, Typography } from '@mui/material'
import StoreFeelGreat from 'GlobalStores/FeelGreat/StoreFeelGreat'
import StoreAuth from 'GlobalStores/User/StoreAuth'
import { observer, useLocalObservable } from 'mobx-react-lite'
import { useCallback, useEffect } from 'react'
import PopperPopup from './PopperPopup/PopperPopup'
import { ReactComponent as CheckIcon } from './PopperPopup/Assets/check-icon.svg'
import StoreTranslate from 'GlobalStores/Cores/Translator/StoreTranslate'
import StoreReferral from 'GlobalStores/Referral/StoreReferral'
import { autorun, reaction, toJS } from 'mobx'
import { get } from 'lodash'
import storeReferral from 'GlobalStores/Referral/StoreReferral'

const MemberPricingUnlock = observer(props => {
    const authLogin = StoreAuth.isAuthorized && StoreAuth.userStatus === 'M'

    const localObserver = useLocalObservable(() => ({
        content: null,
        loading: true
    }))

    const setContentData = useCallback(() => {
        /**
         * If StoreFeelGreat.contents.memberPriceUnlockReadMore is not null, set content to state
         */

        let subTitles = get(StoreFeelGreat.contents.memberPriceUnlockReadMore, 'subTitles', '')
        let displayName = get(storeReferral, 'referralData.displayName', { english: '', native: '' })

        Object.keys(subTitles).map(key => {
            subTitles[key][0] = subTitles[key][0].replace('[ba_name_ref]', displayName[key])

            return key
        })
        localObserver.content = StoreFeelGreat.contents.memberPriceUnlockReadMore
        localObserver.content.subTitles = subTitles
        localObserver.loading = false
    }, [localObserver])

    useEffect(() => {
        autorun(disposer => {
            if ((StoreFeelGreat.contents.memberPriceUnlockReadMore !== null && storeReferral.referralData !== null) || authLogin) {
                localObserver.loading = true
                setTimeout(() => setContentData(), 500)
                disposer.dispose()
            }
        })

        reaction(
            () => StoreTranslate.CurrentLanguage(),
            (n, p) => {
                if (n !== p) {
                    localObserver.loading = true
                    setTimeout(() => setContentData(), 500)
                }
            }
        )

    }, [setContentData, localObserver])

    return authLogin || StoreReferral.HasReferral() ? (
        <>
            {localObserver.loading === false ? (
                localObserver.content ? (
                    <Stack
                        sx={{
                            marginTop: '40px',
                            display: 'flex',
                            justifyContent: 'space-between',
                            flexDirection: 'row',
                            alignItems: 'center',
                            backgroundColor: '#E8F9FF',
                            padding: '10px 0px',
                            borderRadius: '6px',
                            color: '#0BA4D4'
                        }}>
                        <Box
                            sx={{
                                paddingLeft: '16px',
                                display: 'flex',
                                alignItems: 'center',
                                gap: '12px'
                            }}>
                            <Box>
                                <CheckIcon />
                            </Box>
                            <Typography
                                variant="caption"
                                sx={{
                                    color: '#0BA4D4',
                                    fontSize: '12px',
                                    fontWeight: '600'
                                }}>
                                {StoreTranslate.UseLanguage(localObserver.content.buttonTitle)}
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                cursor: 'pointer'
                            }}>
                            <PopperPopup
                                title={StoreTranslate.UseLanguage(localObserver.content.titles)}
                                subTitle={StoreTranslate.UseLanguage(localObserver.content.subTitles)}
                                button={StoreTranslate.UseLanguage(localObserver.content.buttonText)}
                                image={localObserver.content.headerImage}
                                paragraph={StoreTranslate.UseLanguage(localObserver.content.paragraphs)}
                            />
                        </Box>
                    </Stack>
                ) : null
            ) : (
                <Skeleton style={{ marginTop: '40px' }} variant="rounded" width={'100%'} height={'36px'} />
            )}
        </>
    ) : null
})

export default MemberPricingUnlock
