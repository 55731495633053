import { Box, Typography, styled } from '@mui/material'
import BoxSelectionItem from 'Components/Elements/Boxes/BoxSelectionItem'
import BoxSelections from 'Components/Elements/Boxes/BoxSelections'
import { MainTemplate } from 'Components/Template/MainTemplate'
import storeCheckout from 'GlobalStores/Checkout/StoreCheckout'
import StoreTranslate from 'GlobalStores/Cores/Translator/StoreTranslate'
import { T, useTranslate } from 'GlobalStores/Cores/Translator/useTranslate'
import { storeElement } from 'GlobalStores/StoreElement'
import StoreProducts from 'GlobalStores/StoreProducts'
import { observer } from 'mobx-react-lite'

const FeelGreatPack = observer(() => {
    const translate = useTranslate()

    return (
        <Box mt="40px">
            <Typography variant="caption" fontFamily={'Poppins'} fontSize="16px" color={MainTemplate.palette.primary.main} fontWeight={600}>
                <T>customise_your_feelgreat_pack</T>
            </Typography>
            <Box mt="20px">
                <BoxSelections title={translate('unimate_flavor')} gap={storeElement.dynamicWidth < 767 ? '16px' : 'default'}>
                    {StoreProducts.GetFlavors().map(item => {
                        const isOutofStock = storeCheckout.CheckOutofStockItem(item.value)
                        const isBackorder = !!storeCheckout.ShowSystemTag({ itemName: item.value, tagName: 'backorder' })
                        return (
                            <BoxSelectionItem
                                key={item.value}
                                title={StoreTranslate.UseLanguage(item.text)}
                                value={item.value}
                                onClick={value => {
                                    storeCheckout.SetSelectedItem(value)
                                }}
                                selected={storeCheckout.GetSelectedItem() === item.value}
                                textAlign={storeElement.dynamicWidth < 767 ? 'center' : 'left'}
                                flexDirection="column"
                                disabled={isOutofStock}>
                                {isOutofStock ? (
                                    <ProductStatusDetail>
                                        <T>temporarily_out_of_stock</T>
                                    </ProductStatusDetail>
                                ) : isBackorder ? (
                                    <ProductStatusDetail>
                                        <T>temporarily_backorder</T>
                                    </ProductStatusDetail>
                                ) : null}
                            </BoxSelectionItem>
                        )
                    })}
                </BoxSelections>
            </Box>
        </Box>
    )
})

const ProductStatusDetail = styled(Typography)`
    font-family: Inter;
    font-style: normal;
    font-weight: 600 !important;
    font-size: 10px !important;
    color: #a4aebe;
    text-align: center;
    line-height: 16px;
`

export default FeelGreatPack
