import { observer } from 'mobx-react-lite'
import React from 'react'
import SettingCard from '../UserAccountMenu/SettingCard'
import { storeElement } from 'GlobalStores/Cores/Elements/StoreElement'
import { Stack, styled } from '@mui/material'
import Breadcrumb from '../UserAccountMenu/Breadcrumb'
import Invite from './Invite'
import MobileLayoutWrapper from '../UserAccountMenu/MobileLayoutWrapper'
import { useSizeWindow } from 'Helpers/GlobalHelpers'
import CustomReferral from './ReferralLinks/CustomReferral/CustomReferral'

const OrderHistoryWrapper = observer(props => {
    const { width } = useSizeWindow()

    return (
        <>
            {width > 834 ? (
                <SettingCard title={storeElement.settingCard.title || ''} titleAlign={storeElement.settingCard.titleAlign || 'center'} display="block">
                    <Invite />
                </SettingCard>
            ) : (
                <InviteWrapperMobileLayout />
            )}
        </>
    )
})

export const InviteWrapperMobileLayout = () => {
    return (
        <MobileLayoutWrapper>
            <Stack width="100%">
                <Breadcrumb />
                <SettingCard title={storeElement.settingCard.title || ''} titleAlign={storeElement.settingCard.titleAlign || 'left'} display="block" width="100%">
                    <Invite />
                </SettingCard>
                <SettingCard margin="16px 0 0 0" title={storeElement.settingCard.title || ''} titleAlign={storeElement.settingCard.titleAlign || 'left'} display="block" width="100%">
                    <CustomReferral />
                </SettingCard>
            </Stack>
        </MobileLayoutWrapper>
    )
}

const StackMobileLayout = styled(Stack)`
    min-height: 100vh;
    height: auto;
    align-items: center;
    background: linear-gradient(180deg, #f6f9fc 0%, #f6f9fc 101.48%) !important;
    padding: 0 24px 100px 24px;
    width: fit-content;

    @media screen and (min-width: 900px) {
        width: 327px;
    }
`

export default OrderHistoryWrapper
