import { Box, Grid, styled, ThemeProvider, Typography, DialogContent, Dialog } from '@mui/material'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { observer } from 'mobx-react-lite'
import { customTheme } from './PersonalTheme'
import { T, useTranslate } from 'GlobalStores/Cores/Translator/useTranslate'
import { DefaultButton, DefaultTextFields } from '../Forms'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import SuccessPaper from './SuccessPaper'
import { useSizeWindow } from 'Helpers/GlobalHelpers'
import StoreAuth from 'GlobalStores/User/StoreAuth'
import { storeElement } from 'GlobalStores/Cores/Elements/StoreElement'
import { runInAction } from 'mobx'
import SessionAuth from 'GlobalStores/User/SessionAuth'
import StoreTranslate from 'GlobalStores/Cores/Translator/StoreTranslate'
import StoreCountry from 'GlobalStores/Cores/County/StoreCountry'
import { PathHelper } from 'GlobalStores/Cores/Helpers/PathHelper'
import { FeelGreatAPI } from 'Services/FeelGreat/APIs'
import storeAuth from 'GlobalStores/User/StoreAuth'

const ChangePassword = observer(props => {
    const translate = useTranslate()
    const navigate = useNavigate()
    const { width } = useSizeWindow()
    const [loading, setLoading] = useState(false)
    const [updateError, setUpdateError] = useState(false)
    const [open, setOpen] = useState(false)

    let baId = storeAuth.GetId()

    const validationSchema = yup.object({
        currentPassword: yup.string(translate('enter_your_current_password')).required(translate('current_password_is_required')),
        newPassword: yup
            .string(translate('enter_your_new_password'))
            .min(4, translate('password_minimum_4_characters'))
            .required(translate('new_password_is_required'))
    })

    const handleLogout = async () => {
        StoreAuth.logout()
        SessionAuth.RemoveSession(true)
        localStorage.setItem('from-logout', '1')
        let pathRedirect = ''
        if (/localhost/.test(window.location.hostname)) {
            pathRedirect = `//${window.location.hostname}:3000${PathHelper.BuildBasename(
                StoreCountry.CountryLowerCase()
            )}?lang=${StoreTranslate.CurrentLanguage()}&logout`
        } else {
            pathRedirect = `//${window.location.hostname}${PathHelper.BuildBasename(
                StoreCountry.CountryLowerCase()
            )}?lang=${StoreTranslate.CurrentLanguage()}&logout`
        }
        window.location.href = pathRedirect
    }

    const formik = useFormik({
        initialValues: {
            currentPassword: '',
            newPassword: ''
        },
        validationSchema: validationSchema,
        onSubmit: async values => {
            setLoading(true)
            let params = {
                currentPassword: values.currentPassword,
                newPassword: values.newPassword
            }
            const [res, err] = await StoreAuth.changePassword(params)

            setLoading(false)
            if (err) {
                setUpdateError(err)
            }
            if (res) {
                setOpen(true)
                await FeelGreatAPI.deleteCache(baId)
                setTimeout(() => {
                    handleLogout()
                }, 3000)
            }
        }
    })

    const goBack = () => {
        navigate(-1)
    }

    useEffect(() => {
        runInAction(() => {
            storeElement.cardTitle.titleStep2 = <T>settings</T>
        })

        return () => {
            runInAction(() => {
                storeElement.cardTitle.titleStep2 = ''
            })
        }
    }, [])

    return (
        <ThemeProvider theme={customTheme}>
            <div style={width >= 830 ? { margin: '0 60px' } : {}}>
                <Typography variant="h3" fontWeight={600} textAlign="center">
                    <T>change_password</T>
                </Typography>
                <DescParagraph style={{ fontSize: width > 830 ? '18px' : '14px' }}>
                    <T>change_password_desc</T>
                </DescParagraph>
                <form onSubmit={formik.handleSubmit} autoComplete="off" noValidate>
                    <Grid container mt={'14px'} rowSpacing={'16px'} columnSpacing={'30px'}>
                        <Grid item xs={12}>
                            <Box noValidate sx={{ maxWidth: '100%' }}>
                                <DefaultTextFields
                                    inputFontSize={width > 830 ? '24px' : '16px'}
                                    type="password"
                                    password
                                    label={translate('current_password')}
                                    autoComplete="off"
                                    id="currentPassword"
                                    name="currentPassword"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.currentPassword && Boolean(formik.errors.currentPassword)}
                                    helpertext={formik.touched.currentPassword && formik.errors.currentPassword}
                                />
                            </Box>

                            <Box mt={'20px'} noValidate sx={{ maxWidth: '100%' }}>
                                <DefaultTextFields
                                    inputFontSize={width > 830 ? '24px' : '16px'}
                                    type="password"
                                    password
                                    label={translate('new_password')}
                                    autoComplete="off"
                                    id="newPassword"
                                    name="newPassword"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.newPassword && Boolean(formik.errors.newPassword)}
                                    helpertext={formik.touched.newPassword && formik.errors.newPassword}
                                />
                            </Box>
                            {updateError ? (
                                <Box
                                    mt={'20px'}
                                    sx={{
                                        maxWidth: '100%',
                                        borderRadius: '8px',
                                        backgroundColor: '#FFE7EB',
                                        padding: '15px 27px',
                                        textAlign: 'center'
                                    }}>
                                    <Typography variant="desc" sx={{ width: '100%' }} fontSize="14px" fontWeight={300} color="#FF6187">
                                        {translate('error_change_password')}
                                    </Typography>
                                </Box>
                            ) : null}
                        </Grid>
                    </Grid>

                    <Grid container mt={'40px'} display={'flex'} flexDirection={'column'} alignItems={'center'}>
                        <Grid
                            item
                            // xs={6}
                            // sm={6}
                            sx={{
                                display: 'flex',
                                justifyContent: 'end',
                                alignItems: 'center',
                                width: width <= 834 ? '100%' : '344px'
                            }}>
                            <DefaultButton
                                disabled={!(formik.isValid && formik.dirty)}
                                type="submit"
                                fullWidth={true}
                                titleText={translate('save')}
                                isloading={loading.toString()}
                                backgroundcolor={'#003B6F'}
                                width={'100%'}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={6}
                            sm={6}
                            mt={'20px'}
                            sx={{
                                display: 'flex',
                                justifyContent: 'start',
                                alignItems: 'center'
                            }}>
                            <Typography
                                variant="action"
                                fontSize={16}
                                fontFamily={500}
                                onClick={() => {
                                    if (!loading) {
                                        goBack()
                                    }
                                }}>
                                <T>cancel</T>
                            </Typography>
                        </Grid>
                    </Grid>
                </form>
            </div>

            <Dialog
                open={open}
            >
                <DialogContent>
                    <SuccessPaper title={translate('success')} desc={translate('change_password_success_description')} />
                </DialogContent>
            </Dialog>

        </ThemeProvider>
    )
})

const DescParagraph = styled('p')`
    margin-top: 20px;
    margin-bottom: 0px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #003b6f;
    font-style: normal;
`

export default ChangePassword
