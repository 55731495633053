import * as React from 'react'
import { Typography, IconButton, Popper, Fade, Paper, styled } from '@mui/material'
import { ReactComponent as InfoIcon } from './Assets/Info.svg'
import { ReactComponent as CloseIcon } from './Assets/close.svg'
import { ReactComponent as CloseWhiteIcon } from './Assets/closeWhite.svg'
import { useSizeWindow } from 'Helpers/GlobalHelpers'

export default function PopperPopup({
    paperWidth = '307px',
    title = `What’s a member invite code?`,
    caption = `Our best prices are available for people who work directly with our affiliates. Ask your associate for a member invite
    code, so you can get ongoing access to member pricing.`,
    contactInfo = null
}) {
    const [arrowRef, setArrowRef] = React.useState(null)
    const [anchorEl, setAnchorEl] = React.useState(null)

    const { width } = useSizeWindow()

    const handleClick = event => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    return (
        <>
            <IconButton sx={{ padding: '0' }} onClick={handleClick} aria-describedby={'referPoper'}>
                <InfoIcon />
            </IconButton>

            <Popper
                sx={{
                    top: width <= 834 ? '-54px !important' : '16px !important',
                    borderRadius: '8px !important'
                }}
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                placement={'bottom'}
                transition
                modifiers={[
                    {
                        name: 'arrow',
                        enabled: true,
                        options: {
                            element: arrowRef
                        }
                    }
                ]}>
                {({ TransitionProps }) => (
                    <Fade {...TransitionProps} timeout={350}>
                        <Paper
                            sx={{
                                borderRadius: width <= 834 ? '0px !important' : '',
                                width: width <= 834 ? 'initial' : paperWidth,
                                padding: '20px 20px 30px 20px',
                                backgroundColor: width <= 834 ? '#153862' : '#fff'
                            }}>
                            {width > 834 && (
                                <Arrow ref={setArrowRef}>
                                    <Triangle />
                                </Arrow>
                            )}

                            <Content>
                                <div className="content-action">
                                    {width <= 834 ? <CloseWhiteIcon onClick={() => handleClose()} /> : <CloseIcon onClick={() => handleClose()} />}
                                </div>
                                <div className="content-title">
                                    {contactInfo && <UserImage bgUrl={contactInfo?.img} />}
                                    <Typography variant="h3" color={width <= 834 ? '#fff !important' : '#153862'}>
                                        {title}
                                    </Typography>
                                </div>
                                {contactInfo && (
                                    <div className="content-contact">
                                        <Typography variant="caption" color={width <= 834 ? '#fff !important' : '#153862'}>
                                            Email:{' '}
                                            <u>
                                                <b>{contactInfo?.email || '123'}</b>
                                            </u>
                                        </Typography>
                                        <Typography variant="caption" color={width <= 834 ? '#fff !important' : '#153862'}>
                                            Phone:{' '}
                                            <u>
                                                <b>{contactInfo?.phone || '123'}</b>
                                            </u>
                                        </Typography>
                                    </div>
                                )}

                                <Typography
                                    variant="caption"
                                    sx={{
                                        color: width <= 834 ? '#fff !important' : '#153862 !important',
                                        textAlign: caption ? 'left !important' : 'center !important'
                                    }}>
                                    {caption}
                                </Typography>
                            </Content>
                        </Paper>
                    </Fade>
                )}
            </Popper>
        </>
    )
}

const Arrow = styled('span')`
    top: -10px;
    position: absolute;
    width: 20px;
    height: 10px;
`

const Triangle = styled('div')`
    content: '';
    box-sizing: border-box;
    position: absolute;
    width: 19px;
    height: 19px;
    background: #fff;
    transform: rotate(45deg);
`

const Content = styled('div')`
    display: flex;
    flex-direction: column;
    font-family: 'Inter';
    font-style: normal;
    h3 {
        font-weight: 600;
        font-size: 16px;
        line-height: 18px;
        text-align: center;
        margin-bottom: 16px;
    }
    span {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        text-align: center;
        color: #003764;
    }
    .content-action {
        display: flex;
        flex-direction: row-reverse;
        svg {
            width: 16px;
            height: 16px;
            cursor: pointer;
        }
    }

    .content-title {
        display: flex;
        flex-direction: row;
    }

    .content-contact {
        margin: 16px 0;
        display: flex;
        flex-direction: column;
        align-items: baseline;
    }
`

const UserImage = styled('div')`
    width: 36px;
    height: 36px;
    background-color: #e5e5e5;
    border-radius: 50%;
    background-image: ${({ bgUrl }) => `url(${bgUrl})`};
    background-size: contain;
    margin-right: 10px;
`
