import { Stack } from '@mui/material'
import { IconSpinner } from 'Components/Elements/Spinner/IconSpinner'
import storeCountry from 'GlobalStores/Cores/County/StoreCountry'
import storeReferral from 'GlobalStores/Referral/StoreReferral'
import SessionAuth from 'GlobalStores/User/SessionAuth'
import storeAuth from 'GlobalStores/User/StoreAuth'
import { observer } from 'mobx-react-lite'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

const LoginWrapper = observer(({ children }) => {
    const params = useParams()
    const navigate = useNavigate()
    const qs = useMemo(() => new URLSearchParams(window.location.search), [])
    const [loading, setLoading] = useState(true)

    const RestoreData = useCallback(() => {
        if (!storeAuth.IsAuthorized()) {
            SessionAuth.ShouldRestore()
                .then(() => {
                    storeAuth
                        .RestoreSession(SessionAuth.GetLoginExtends()[storeCountry.CountryLowerCase()])
                        .then(() => {
                            if (/\/login/.test(window.location.pathname)) {
                                console.log('redirect from login')
                                navigate('/', { replace: true })
                            } else if (params.customerId) {
                                console.log('redirect to referral')
                                navigate('/', { replace: true })
                            }
                            setLoading(false)
                        })
                        .catch(error => {
                            if (/\/login/.test(window.location.pathname)) {
                                setLoading(false)
                                SessionAuth.RemoveSession(true)
                            } else {
                                navigate('/login', { replace: true })
                            }
                        })
                })
                .catch(() => {
                    console.log('there is no session to restore')
                    setLoading(false)
                    if (/user+/.test(window.location.pathname)) {
                        navigate('/login', { replace: true })
                    }
                })
        } else {
            setLoading(false)
        }
    }, [params.customerId, navigate])

    useEffect(() => {
        RestoreData()
    }, [params.customerId, navigate, RestoreData])

    return (
        <>
            {loading ? (
                <Stack minHeight={'calc(100vh - 137px)'} bgcolor={'#d2d9e1'} alignItems={'center'} justifyContent={'center'}>
                    <IconSpinner />
                </Stack>
            ) : (
                <Stack minHeight={'calc(100vh - 137px)'} bgcolor={'#d2d9e1'}>
                    {children}
                </Stack>
            )}
        </>
    )
})

export default LoginWrapper
