import { Box, styled, Typography } from '@mui/material'
import { observer } from 'mobx-react-lite'

const TextDisplay = observer(({ fullWidth = true, label = '', value = '' }) => {
    return (
        <Box width={fullWidth ? '100%' : 'auto'} pb={'24px'}>
            <Label variant="h5">{label}</Label>
            <Value variant="h6">{value}</Value>
        </Box>
    )
})

const Label = styled(Typography)`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #023764;
`

const Value = styled(Typography)`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px; 
    color: #023764;
`

export default TextDisplay
