import StoreCountry from 'GlobalStores/Cores/County/StoreCountry'
import { makeAutoObservable, runInAction, toJS } from 'mobx'
import Australia from './Australia'
import Singapore from './Singapore'
import Newzealand from './Newzealand'
import Philippines from './Philippines'
import Taiwan from './Taiwan'
import Malaysia from './Malaysia'
import Morocco from './Morocco'
import Hongkong from './Hongkong'
import Japan from './Japan'
import Egypt from './Egypt'
import Turkiye from './Turkiye'
import Jordan from './Jordan'

class Checkout {
    instance = null
    formItems = []

    ArrangeCreateCheckoutForm() {
        if (this.Instance()) {
            return this.Instance().ArrangeCreateCheckoutForm
        }
    }

    GetPaymentStep() {
        if (this.Instance()) {
            return this.Instance().GetPaymentStep()
        }
    }

    GetBreadcrumbOption() {
        if (this.Instance()) {
            return this.Instance().GetBreadcrumbOption()
        }
    }

    GetShippingMethod() {
        if (this.Instance()) {
            try {
                return this.Instance().GetShippingMethod()
            } catch (error) {
                return null
            }
        }
    }

    GetOrderSummaryConfig() {
        if (this.Instance()) {
            try {
                return this.Instance().GetOrderSummaryConfig()
            } catch (error) {
                return null
            }
        }
    }

    GetBypassPage() {
        if (this.Instance()) {
            try {
                return this.Instance().GetBypassPage()
            } catch (error) {
                return null
            }
        }
    }

    GetNotShowReferralBar() {
        if (this.Instance()) {
            try {
                return this.Instance().GetNotShowReferralBar()
            } catch (error) {
                return null
            }
        }
    }

    GetPageConfig() {
        if (this.Instance()) {
            try {
                return this.Instance().GetPageConfig()
            } catch (error) {
                return null
            }
        }
    }

    GetLandingLayout() {
        if (this.Instance()) {
            return this.Instance().GetLandingLayout()
        }
    }

    GetProductLayout() {
        if (this.Instance()) {
            return this.Instance().GetProductLayout()
        }
    }

    MapAutoAddressToForm(form, autoAddressData) {
        runInAction(() => {
            this.Instance().MapAutoAddressToForm(form, autoAddressData)
        })
    }

    Instance() {
        if (!this.instance) {
            if (StoreCountry.Country3() === 'AUS') {
                this.instance = new Australia()
            } else if (StoreCountry.Country3() === 'SGP') {
                this.instance = new Singapore()
            } else if (StoreCountry.Country3() === 'NZL') {
                this.instance = new Newzealand()
            } else if (StoreCountry.Country3() === 'PHL') {
                this.instance = new Philippines()
            } else if (StoreCountry.Country3() === 'TWN') {
                this.instance = new Taiwan()
            } else if (StoreCountry.Country3() === 'MYS') {
                this.instance = new Malaysia()
            } else if (StoreCountry.Country3() === 'MAR') {
                this.instance = new Morocco()
            } else if (StoreCountry.Country3() === 'HKG') {
                this.instance = new Hongkong()
            } else if (StoreCountry.Country3() === 'JPN') {
                this.instance = new Japan()
            } else if (StoreCountry.Country3() === 'TUR') {
                this.instance = new Turkiye()
            } else if (StoreCountry.Country3() === 'JOR') {
                this.instance = new Jordan()
            } else if (StoreCountry.Country3() === 'EGY') {
                this.instance = new Egypt()
            }
        }

        return this.instance
    }

    AutoAddressDisplayKeys() {
        if (this.Instance()) {
            return this.Instance().autoAddressDisplayKeys
        }
    }

    FormToFormat(type, form) {
        if (this.Instance()) {
            return this.Instance().FormToFormat(type, form)
        }
    }

    GetFormat(type) {
        if (this.Instance()) {
            return this.Instance().GetFormat(type)
        }
    }

    GetUuid() {
        if (this.Instance()) {
            return this.Instance().GetUuid()
        }
        return ''
    }

    SetUuid(value) {
        if (this.Instance()) {
            return this.Instance().SetUuid(value)
        }
    }

    ExcludedFieldsShippingIfCreateAccount() {
        if (this.Instance()) {
            return this.Instance().ExcludedFieldsShippingIfCreateAccount
        }
    }

    ExcludedFieldsShipping() {
        if (this.Instance()) {
            return this.Instance().ExcludedFieldsShipping
        }
    }

    CreditCardEncrypted = CreditCard => {
        let rearrangeExpDate = CreditCard.creditCardExpires.split('/')
        rearrangeExpDate = `${parseInt(rearrangeExpDate[1]) + 2000}-${rearrangeExpDate[0]}`

        return {
            type: 'AuthorizeAndCapture',
            method: 'CreditCard',
            amount: 'this.terms.total',
            methodDetails: {
                payer: CreditCard.payer,
                creditCardNumber: CreditCard.creditCardNumber,
                creditCardExpires: rearrangeExpDate,
                creditCardSecurityCode: CreditCard.creditCardSecurityCode
            },
            billToAddress: {
                country: StoreCountry.Country2()
            }
        }
    }

    CreditCardForPaymentV1(CreditCard) {
        // return {
        //     method: 'creditCard',
        //     amount: GetTotalSummary('wholesale'),
        //     payer: CreditCard.cardName,
        //     creditCardNumber: CreditCard.cardNumber,
        //     creditCardExpires: CreditCard.cardYear + '-' + CreditCard.cardMonth,
        //     creditCardSecurityCode: CreditCard.cardCVV
        // }
    }

    async SubmitPayment(uuid, detailPage, CreditCardEncrypted, billingAddressForm, products, options) {
        if (this.Instance()) {
            const response = await this.Instance().SubmitPayment(uuid, detailPage, CreditCardEncrypted, billingAddressForm, products, options)
            return response
        }
    }

    GetPaymentId() {
        if (this.Instance()) {
            return this.Instance().GetPaymentId()
        }
    }

    CustomShippingForms() {
        if (this.Instance()) {
            return this.Instance().CustomShippingForms
        }
    }

    CustomCreateAccountWithShippingForms() {
        if (this.Instance()) {
            return this.Instance().CustomCreateAccountForms
        }
    }

    ExtendShippingForms() {
        if (this.Instance()) {
            return this.Instance().ExtendShippingForms
        }
    }

    ExtendCreateAccountWithShippingForms() {
        if (this.Instance()) {
            return this.Instance().ExtendCreateAccountForms
        }
    }

    PickupShippingForms() {
        if (this.Instance()) {
            return this.Instance().PickupShippingForms
        }
    }

    GetBranchPickupDetail() {
        if (this.Instance()) {
            return this.Instance().GetBranchPickupDetail()
        }
    }

    SetPaymentMethod(value) {
        if (this.Instance()) {
            try {
                return this.Instance().SetPaymentMethod(value)
            } catch (error) {
                return null
            }
        }
    }

    GetPaymentMethod() {
        if (this.Instance()) {
            try {
                return this.Instance().GetPaymentMethod()
            } catch (error) {
                return null
            }
        }
    }

    async CreateAccount(uuid, accForm, shippingForm, referral, cartItems) {
        if (this.Instance()) {
            return await this.Instance().CreateAccount(uuid, accForm, shippingForm, referral, cartItems)
        }
    }

    async GetPaymentChannel() {
        if (this.Instance) {
            return await this.Instance().paymentChannel
        }
    }

    constructor() {
        makeAutoObservable(this)
    }
}

export default Checkout
