import { Grid, Skeleton, Typography, ThemeProvider, Stack, styled, Divider } from '@mui/material'
import { T, useTranslate } from 'GlobalStores/Cores/Translator/useTranslate'
import { observer } from 'mobx-react-lite'
import { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import ImageTool from './ProfilePictureTool'
import { customTheme } from './PersonalTheme'
import SettingItem from './SettingItem'
import StoreAuth from 'GlobalStores/User/StoreAuth'
import StoreUser from 'GlobalStores/User/StoreUser'
import { useSizeWindow } from 'Helpers/GlobalHelpers'
import { runInAction } from 'mobx'
import { storeElement } from 'GlobalStores/Cores/Elements/StoreElement'
import ChangeProfileImage from './ChangeProfileImage/ChangeProfileImage'
import CustomDisplayName from './CustomDisplayName/CustomDisplayName'

const Settings = observer(props => {
    const inputUploadRef = useRef(null)
    const { width } = useSizeWindow()
    const translate = useTranslate()
    const navigate = useNavigate()
    const { userData } = props

    const [userDataFullfiled, fullFillUserData] = useState(null)

    const [image, setImage] = useState(null)
    const [imageUploading, setImageUploading] = useState(false)
    const [showImageTools, setShowImageTools] = useState(false) 

    useEffect(() => {
        runInAction(() => {
            storeElement.cardTitle.titleStep1 = <T>settings</T>
        })
        return () => {
            runInAction(() => {
                storeElement.cardTitle.titleStep1 = ''
            })
        }
    })

    useEffect(() => {
        if (userData) {
            setTimeout(() => {
                fullFillUserData(userData || null)
            }, 1000)
        }
    }, [userData])

    const handleFileClick = () => {
        inputUploadRef.current.click()
        let aceptDevices = ['iPad', 'iPhone'] 
        if(!aceptDevices.includes(window.navigator?.platform)){
            setImageUploading(true)
            document.body.onfocus = () => {
                setImageUploading(false)
                document.body.onfocus = null
            }
            document.body.ontouchstart = e => {
                setImageUploading(false)
            }
        }
        
    }

    const handleFileChange = event => { 
        const fileObj = event.target.files && event.target.files[0]
        if (!fileObj) {
            return
        }
        setImage(window.URL.createObjectURL(fileObj))
        setShowImageTools(true)
        event.target.value = null
    }

    const handleOnSaveProfilePicturre = async picture => {
        setImageUploading(true)
        const [res, err] = await StoreAuth.updateUserProfilePicture(picture)
        if (err) alert(err)
        if (res) {
            const userResponse = await StoreAuth.getCustomerMenu()
            let ushop = userResponse.onself.ushop
            ushop.profile.profilePicture = res
            StoreUser.setUserData(ushop)
        }
        setImageUploading(false)
    }

    const handleNav = path => {
        navigate(path)
    }

    const handleRemoveProfilePicture = async () => {
        setImageUploading(true);
        const [res, err] = await StoreAuth.removeUserProfilePicture();

        if (err) {
            alert(err)
        }

        if (res.status == 204) {
            const userResponse = await StoreAuth.getCustomerMenu()
            let ushop = userResponse.onself.ushop
            ushop.profile.profilePicture.sizes = []
            StoreUser.setUserData(ushop)
        }

        setTimeout(() => {
            setImageUploading(false)
        }, 1500)

    }

    return (
        <ThemeProvider theme={customTheme}>
            {userDataFullfiled ? (
                <Grid container display={'flex'} flexDirection={'column'} sx={{ opacity: imageUploading ? 0.3 : 1 }}>
                    <Typography variant="h3" color={'#003B6F'} fontWeight={700} textAlign={'left'} mb={'30px'} fontSize={'24px !important'}>
                        <T>settings</T>
                    </Typography>

                    {showImageTools && (
                        <ImageTool open={showImageTools} imgFile={image} onCloseModal={setShowImageTools} callback={handleOnSaveProfilePicturre} />
                    )}

                    <input style={{ display: 'none' }} ref={inputUploadRef} type="file" onChange={handleFileChange} />
                    <ChangeProfileImage 
                        callbackRemoveProfile={handleRemoveProfilePicture} 
                        label={translate('change_profile_image')} 
                        callback={handleFileClick} 
                        isLoading={imageUploading} 
                    />
                    <CustomDisplayName preferredName={userDataFullfiled.preferredName}/>
                    <SettingItem label={translate('change_email')} value={userDataFullfiled.email} callback={() => handleNav('email-edit')} />
                    <SettingItem label={translate('change_mobile')} value={userDataFullfiled.mobilePhone} callback={() => handleNav('mobile-number-edit')} />
                    <SettingItem label={translate('change_password')} value={'********'} callback={() => handleNav('change-password')} />
                </Grid>
            ) : (
                <Stack>
                    <Skeleton variant="rounded" animation="wave" width={'30%'} height={40} />
                    <Skeleton sx={{ marginTop: '32px' }} variant="rounded" animation="wave" width={'30%'} height={16} />
                    <Skeleton sx={{ marginTop: '20px' }} variant="rounded" animation="wave" width={'100%'} height={16} />

                    <ImageSkeleton>
                        <Stack>
                        <Skeleton variant="rounded" animation="wave" width={'95px'} height={'95px'}/>
                        </Stack>
                        <Stack width={'90%'} gap={'20px'} sx={{ flexDirection: width < 768 ? 'column' : 'row', marginLeft: width < 600 ? '20px' : '32px' }}>
                            <Skeleton variant="rounded" animation="wave" width={width > 430 ? '240px' : '100%'} height={'50px'} />
                            <Skeleton variant="rounded" animation="wave" width={width > 430 ? '240px' : '100%'} height={'50px'} />
                        </Stack>
                    </ImageSkeleton>

                    <Divider sx={{ marginTop: '24px', marginBottom: '24px' }} />

                    <DisplayNameSkeleton>
                        <Stack sx={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                            <Skeleton variant="rounded" animation="wave" width={'30%'} height={16} />
                            <Skeleton variant="rounded" animation="wave" width={'100px'} height={16} />
                        </Stack>
                        <Skeleton sx={{ marginTop: '20px' }} variant="rounded" animation="wave" width={'100%'} height={16} />
                        <Skeleton sx={{ marginTop: '20px' }} variant="rounded" animation="wave" width={'30%'} height={36} />
                    </DisplayNameSkeleton>

                    {[0, 1, 2].map(() => {
                        return (
                            <>
                                <Divider sx={{ marginTop: '24px', marginBottom: '24px' }} />
                                <Stack >
                                    <Stack sx={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                                        <Skeleton variant="rounded" animation="wave" width={'30%'} height={16} />
                                        <Skeleton variant="rounded" animation="wave" width={'100px'} height={16} />
                                    </Stack>
                                    <Skeleton sx={{ marginTop: '20px' }} variant="rounded" animation="wave" width={'30%'} height={16} />
                                </Stack>
                            </>
                        )
                    })}
                </Stack>
            )}
        </ThemeProvider>
    )
})

const ImageSkeleton = styled(Stack)`
    flex-direction: row;
    align-items: center;
    margin-top: 32px;

    @media screen and (min-width: 600px) {
        
    }

`

const DisplayNameSkeleton = styled(Stack)``

export default Settings
