import { Box, Container, Stack, Skeleton, styled, ThemeProvider, Typography } from '@mui/material'
import BoxSelectionItem from 'Components/Elements/Boxes/BoxSelectionItem'
import BoxSelections from 'Components/Elements/Boxes/BoxSelections'
import DefaultButton from 'Components/Elements/Forms/DefaultButton'
import { MainTemplate } from 'Components/Template/MainTemplate'
import StoreCheckout from 'GlobalStores/Checkout/StoreCheckout'
import StoreCountry from 'GlobalStores/Cores/County/StoreCountry'
import StoreTranslate from 'GlobalStores/Cores/Translator/StoreTranslate'
import { T, useTranslate } from 'GlobalStores/Cores/Translator/useTranslate'
import StoreFeelGreat from 'GlobalStores/FeelGreat/StoreFeelGreat'
import StoreProducts from 'GlobalStores/StoreProducts'
import { NumberHelpers } from 'Helpers/NumberHelpers'
import { ObjectHelpers } from 'Helpers/ObjectHelpers'
import { autorun, reaction, runInAction } from 'mobx'
import { observer, useLocalObservable } from 'mobx-react-lite'
import { useEffect, useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import StoreAuth from 'GlobalStores/User/StoreAuth'
import { storeElement } from 'GlobalStores/StoreElement'
import MemberPricingUnlock from 'Components/Elements/MemberPricingUnlock/MemberPricingUnlock'
import StoreReferral from 'GlobalStores/Referral/StoreReferral'

import StoreLoader from 'GlobalStores/Cores/Elements/StoreLoader'
import Errors from 'Components/Elements/Errors/Errors'
import MoneyBack from 'Components/Elements/Checkout/MoneyBack'
import { get, isEmpty } from 'lodash'
import Gallery from 'Components/Gallery/Gallery'
import FeelGreatPack from 'Components/Pages/ProductElements/FeelgreatPack'
import storeCheckout from 'GlobalStores/Checkout/StoreCheckout'
import storeUser from 'GlobalStores/User/StoreUser'
import ButtonWithPopup from 'Components/Elements/Forms/ButtonWithPopup'
import storeAuth from 'GlobalStores/User/StoreAuth'
import storeCountry from 'GlobalStores/Cores/County/StoreCountry'

const ProductDetailSelection = observer(() => {
    const translate = useTranslate()
    const navigate = useNavigate()

    const refElement = useRef(null)

    const [loading, setLoading] = useState(false)
    const [checkoutLoading, setCheckoutLoading] = useState(false)

    const LOGIN_OR_REFERRAL = StoreAuth.IsAuthorized() || StoreReferral.HasReferral()

    const [productLoading, setProductLoading] = useState(false)

    const [canScroll, setCanScroll] = useState(false)

    const [cartHistory, setCartHistory] = useState(false)

    let cartTotal = false
    if (StoreCheckout.GetSelectedPurchaseOption() === 'subscribe') {
        cartTotal = true
    } else {
        cartTotal = LOGIN_OR_REFERRAL
    }

    const localObserver = useLocalObservable(() => ({
        scrollToPosition: 460
    }))

    function scrollToSmoothly(pos, time) {
        var currentPos = window.pageYOffset
        var start = null
        if (time === null) {
            time = 500
        }
        pos = +pos
        time = +time
        window.requestAnimationFrame(function step(currentTime) {
            start = !start ? currentTime : start
            var progress = currentTime - start
            if (currentPos < pos) {
                window.scrollTo(0, ((pos - currentPos) * progress) / time + currentPos)
            } else {
                window.scrollTo(0, currentPos - ((currentPos - pos) * progress) / time)
            }
            if (progress < time) {
                window.requestAnimationFrame(step)
            } else {
                window.scrollTo(0, pos)
            }
        })
    }

    useEffect(() => {
        runInAction(() => {
            StoreCheckout.completedActionsLink = [...StoreCheckout.completedActionsLink, `/products`]
        })

        autorun(disposer => {
            if (StoreProducts.GetIsInit()) {
                let foundItem = ''

                if (StoreCheckout.GetSelectedItem() === '') {
                    StoreProducts.GetFlavors().forEach(({ value: flavor }) => {
                        if (foundItem) return

                        if (!StoreCheckout.CheckOutofStockItem(flavor)) {
                            StoreCheckout.SetSelectedItem(flavor)
                            foundItem = flavor
                        }
                    })
                    if (!foundItem) {
                        StoreCheckout.SetSelectedItem(StoreFeelGreat.defaultValues.flavor)
                    }
                }

                if (StoreCheckout.GetSelectedPurchaseOption() === '') {
                    const optionList = Object.keys(get(StoreProducts.GetProducts(), foundItem || StoreFeelGreat.defaultValues.flavor, {})) || []
                    const hasDefaultInList = optionList.includes(StoreFeelGreat.defaultValues.purchaseOption)
                    StoreCheckout.SetSelectedPurchaseOption(hasDefaultInList ? StoreFeelGreat.defaultValues.purchaseOption : get(optionList, '0', ''))
                }

                if (StoreCheckout.GetSelectedItem() !== '' && StoreCheckout.GetSelectedPurchaseOption() !== '') {
                    disposer.dispose()
                }
            }
        })

        reaction(
            () => StoreCheckout.uuid,
            (value, prevValue, disposer) => {
                if (value.length > 0) {
                    if (['JPN'].includes(StoreCountry.Country3())) {
                        alternativeCheckout(value)
                    } else {
                        const path = StoreAuth.IsAuthorized() ? 'shipping' : 'create-account'
                        StoreCheckout.GoToNextStep([`/checkout/${path}?uuid=${value}`])
                        navigate(`/checkout/${path}?uuid=${value}`)
                    }
                    // disposer.dispose()
                }
            }
        )

        autorun(disposer => {
            if (['JPN'].includes(StoreCountry.Country3()) && storeAuth.isLogin === true && !storeAuth.loadingUserMenu && StoreCheckout.TotalPrice() === 0) {
                storeCheckout.showLoginModal = false
                // onCheckout()
                disposer.dispose()
            }
        })
    }, [])

    const alternativeCheckout = async value => {
        // for japan only
        if (!StoreReferral.HasReferral()) {
            const userData = storeUser.CustomerData().userData
            const userAddress = storeUser.CustomerData().MainAddress()
            const dataToSet = {
                fullName: storeUser.CustomerData().FullName(),
                phone: storeUser.CustomerData().MobilePhone(),
                email: storeUser.CustomerData().Email(),
                house_number: userAddress.address1,
                town: userAddress.address2,
                district: userAddress.city,
                prefecture: userAddress.state,
                zip_code: userAddress.zip
            }

            StoreCheckout.GetCheckoutForms().map(item => {
                if (userAddress.country !== StoreCountry.Country2()) {
                    return
                }
                StoreCheckout.SetCheckoutFormValue(item.key, dataToSet[item.key])
            })
            const response = await StoreCheckout.ContinueToPayment()
            if (response.success === true) {
                setTimeout(() => {
                    runInAction(() => {
                        StoreCheckout.GoToNextStep([`/checkout/shipping`, `/checkout/payment?uuid=${value}`])
                    })

                    navigate(`/checkout/payment?uuid=${value}`)
                }, 1000)
            } else {
                // TODO: Add error if can't submit format
                setTimeout(() => {
                    runInAction(() => {
                        StoreCheckout.GoToNextStep([`/checkout/shipping?uuid=${value}`])
                    })

                    navigate(`/checkout/shipping?uuid=${value}`)
                }, 1000)
            }
        } else {
            const path = StoreAuth.IsAuthorized() ? 'shipping' : 'create-account'
            StoreCheckout.GoToNextStep([`/checkout/${path}?uuid=${value}`])
            navigate(`/checkout/${path}?uuid=${value}`)
        }
    }

    const onCheckout = async (addToCart = true, redirect = null) => {
        try {
            if (addToCart) {
                let allow = await StoreCheckout.CheckCartLimit(StoreCheckout.GetSelectedItem(), 1, StoreCheckout.GetSelectedPurchaseOption())
                if (allow) return
                setLoading(true)
                StoreCheckout.AddToCart(StoreCheckout.GetSelectedItem(), 1, StoreCheckout.GetSelectedPurchaseOption())
            } else {
                setCheckoutLoading(true)
            }

            if (StoreAuth.IsAuthorized()) {
                await StoreCheckout.CreateUUID()
                setLoading(false)
                setCheckoutLoading(false)
            } else {
                const responseUuid = await StoreCheckout.CreateUUID(true)
                runInAction(() => {
                    StoreCheckout.period = responseUuid.period
                    StoreCheckout.uuid = responseUuid.uuid
                })
            }
        } catch (e) {
            setCheckoutLoading(false)
            setLoading(false)
        }
    }

    useEffect(() => {
        setCartHistory(!ObjectHelpers.isEmpty(StoreCheckout.GetCart()))

        return () => {
            setCheckoutLoading(true)
            setLoading(false)
            StoreLoader.SetLoadFormatApi(true)
            StoreCheckout.SetOrderSpecError([])
        }
    }, [])

    useEffect(() => {
        if (!refElement.current) return
        const observe = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    setCanScroll(false)
                } else {
                    setCanScroll(true)
                }
            })
        })
        observe.observe(refElement.current)
        return () => observe.disconnect()
    }, [productLoading])

    return (
        <ProductsStyled isJapan={storeCountry.Country2() === 'JP'}>
            {!productLoading ? (
                <>
                    {storeElement.dynamicWidth >= 1180 ? (
                        <Stack>
                            <Gallery />
                        </Stack>
                    ) : null}
                    <Stack>
                        <Box>
                            <Typography variant="h3" fontSize={36}>
                                <T>Feel Great</T>
                            </Typography>
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: StoreTranslate.UseLanguage(StoreFeelGreat.GetContent('description'))
                                }}
                                className="list-from-api"
                            />
                        </Box>
                        {storeElement.dynamicWidth < 1180 ? (
                            <Stack mt="40px">
                                <Gallery />
                            </Stack>
                        ) : null}
                        <FeelGreatPack />
                        <Box mt="30px">
                            <BoxSelections title={translate('purchasing_options')} fullwidth gap={storeElement.dynamicWidth < 767 ? '16px' : 'default'}>
                                {StoreFeelGreat.GetPurchaseOptions().map(item => {
                                    const product = StoreProducts.GetProduct(StoreCheckout.GetSelectedItem(), item.value) // flavor, purchase type
                                    // product have onetime and subcription

                                    if (!product) return false
                                    const priceShown = StoreCheckout.HasDiscount() ? get(product, 'price.member', 0) : get(product, 'price.retail')

                                    let strikePrice = get(StoreProducts.GetProduct(StoreCheckout.GetSelectedItem(), item.value), 'price.retail', 0)

                                    const isSelected = StoreCheckout.GetSelectedPurchaseOption() === item.value

                            
                                    const getSubscribePrice = value => {
                                        return value.replaceAll(' ', '') + `/${translate('per_month')}`
                                    }

                                    return (
                                        <BoxSelectionItem
                                            key={item.value}
                                            fullwidth="true"
                                            value={item.value}
                                            onClick={value => {
                                                if (canScroll) {
                                                    scrollToSmoothly(
                                                        document.getElementById('subscription-section').offsetTop - localObserver.scrollToPosition,
                                                        400
                                                    )
                                                }
                                                StoreCheckout.SetSelectedPurchaseOption(value)
                                            }}
                                            selected={isSelected} // use for define class
                                            height="auto"
                                            justifyContent="space-between">
                                            <Box>
                                                <Box>
                                                    <Typography
                                                        variant="caption"
                                                        fontWeight={isSelected ? 600 : 400}
                                                        color={MainTemplate.palette.flavor.main}
                                                        lineHeight="20px">
                                                        {StoreTranslate.UseLanguage(item.text)}
                                                    </Typography>
                                                </Box>
                                                {item.descriptions &&
                                                    item.descriptions[StoreTranslate.IsEnglish() ? 'english' : 'native'].map((item, key) => {
                                                        const onetimePrice = get(
                                                            StoreProducts.GetProduct(StoreCheckout.GetSelectedItem(), 'onetime'),
                                                            'price',
                                                            0
                                                        )

                                                        const subscribePrice = get(
                                                            StoreProducts.GetProduct(StoreCheckout.GetSelectedItem(), 'subscribe'),
                                                            'price',
                                                            0
                                                        )

                                                        let priceOnetimeRetail = onetimePrice.after
                                                        let priceSubscribe = subscribePrice.after

                                                        if (StoreCheckout.HasDiscount()) {
                                                            priceOnetimeRetail = onetimePrice.member
                                                            priceSubscribe = subscribePrice.member
                                                        }

                                                        const price = priceOnetimeRetail - priceSubscribe
                                                        const discount = item.replace('{{diffPrice}}', price)
                                                        const noDiscount = item.replace('{{diffPrice}} AUD off', '')

                                                        return (
                                                            <Box key={key}>
                                                                <Typography
                                                                    variant="muted"
                                                                    style={{
                                                                        opacity: isSelected ? 1 : 0.4,
                                                                        color: isSelected ? '#0B3B61' : 'inherit',
                                                                        fontWeight: 400,
                                                                        fontFamily: 'Inter'
                                                                    }}>
                                                                    {price === 0 || price === null || price === undefined ? noDiscount : discount}
                                                                </Typography>
                                                            </Box>
                                                        )
                                                    })}
                                            </Box>

                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    alignItems: 'flex-end'
                                                }}>
                                                <Box>
                                                    <Typography variant="caption" color={MainTemplate.palette.flavor.main} fontWeight={isSelected ? 600 : 400}>
                                                        {item.value === 'subscribe'
                                                            ? getSubscribePrice(
                                                                  NumberHelpers.currency({
                                                                      currency: StoreCountry.GetCurrencyCode(),
                                                                      number: priceShown,
                                                                      symbol: StoreCountry.GetCurrencySymbol()
                                                                  })
                                                              )
                                                            : NumberHelpers.currency({
                                                                  currency: StoreCountry.GetCurrencyCode(),
                                                                  number: priceShown,
                                                                  symbol: StoreCountry.GetCurrencySymbol()
                                                              })}
                                                    </Typography>
                                                </Box>
                                                {StoreCheckout.HasDiscount() && strikePrice > priceShown ? (
                                                    <Box>
                                                        <Typography variant="through" textAlign={'right'}>
                                                            {NumberHelpers.currency({
                                                                currency: StoreCountry.GetCurrencyCode(),
                                                                number: strikePrice,
                                                                symbol: StoreCountry.GetCurrencySymbol()
                                                            })}
                                                        </Typography>
                                                    </Box>
                                                ) : null}
                                            </Box>
                                        </BoxSelectionItem>
                                    )
                                })}
                            </BoxSelections>
                        </Box>
                        {StoreCheckout.orderSpecError.length > 0 && (
                            <Stack mt={'20px'}>
                                <Errors list={StoreCheckout.orderSpecError} />
                            </Stack>
                        )}
                        {StoreCheckout.SubTotal() > 0 && cartHistory ? (
                            <Stack mt="40px" display={'flex'} flexDirection="row" justifyContent={'space-between'}>
                                <Box>
                                    <Typography variant="h3">
                                        <T>cart_total</T>
                                    </Typography>
                                </Box>
                                <Box display="flex" alignItems="center">
                                    {StoreCheckout.HasDiscount() && StoreCheckout.TotalRetailPrice() > StoreCheckout.SubTotal() ? (
                                        <Typography variant="through" mr={'10px'} display={cartTotal ? 'block' : 'none'}>
                                            {NumberHelpers.currency({
                                                currency: StoreCountry.GetCurrencyCode(),
                                                number: StoreCheckout.TotalRetailPrice(),
                                                symbol: StoreCountry.GetCurrencySymbol()
                                            })}
                                        </Typography>
                                    ) : null}

                                    <Typography variant="h3">
                                        {NumberHelpers.currency({
                                            currency: StoreCountry.GetCurrencyCode(),
                                            number: StoreCheckout.SubTotal(),
                                            symbol: StoreCountry.GetCurrencySymbol()
                                        })}
                                    </Typography>
                                </Box>
                            </Stack>
                        ) : null}

                        <MemberPricingUnlock />
                        <div id="subscription-section">
                            <Stack
                                display={'flex'}
                                spacing={storeElement.dynamicWidth < 600 ? '20px' : null}
                                flexDirection={storeElement.dynamicWidth < 600 ? 'column' : 'row'}
                                alignItems={storeElement.dynamicWidth < 600 ? 'center' : 'unset'}
                                justifyContent={
                                    ObjectHelpers.isEmpty(StoreCheckout.GetCart()) ? 'flex-start' : storeElement.dynamicWidth < 600 ? 'center' : 'space-between'
                                }
                                mt="40px"
                                sx={{
                                    display:
                                        StoreCheckout.noSignupWithoutReferral.includes(StoreCountry.Country2()) &&
                                        !StoreReferral.referralData &&
                                        !storeAuth.IsAuthorized()
                                            ? 'flex'
                                            : 'none'
                                }}>
                                <Stack>
                                    <ButtonWithPopup
                                        loading={loading}
                                        titleText={StoreCheckout.CheckOutofStockSelectedItem() ? translate('out_of_stock') : translate('add_to_cart')}
                                        callback={() => StoreCheckout.SetIsLoginOnly(false)}
                                        onCheckout={onCheckout}
                                    />
                                    {(StoreCheckout.GetUuid() && cartHistory) || storeElement.dynamicWidth < 600 ? <></> : <MoneyBack />}
                                </Stack>
                            </Stack>
                            <Stack
                                display={'flex'}
                                spacing={storeElement.dynamicWidth < 600 ? '20px' : null}
                                flexDirection={storeElement.dynamicWidth < 600 ? 'column' : 'row'}
                                alignItems={storeElement.dynamicWidth < 600 ? 'center' : 'unset'}
                                justifyContent={
                                    ObjectHelpers.isEmpty(StoreCheckout.GetCart()) ? 'flex-start' : storeElement.dynamicWidth < 600 ? 'center' : 'space-between'
                                }
                                mt="40px"
                                sx={{
                                    display:
                                        StoreCheckout.noSignupWithoutReferral.includes(StoreCountry.Country2()) &&
                                        !StoreReferral.referralData &&
                                        !StoreAuth.IsAuthorized()
                                            ? 'none'
                                            : 'flex'
                                }}>
                                <Stack>
                                    <DefaultButton
                                        titleText={StoreCheckout.CheckOutofStockSelectedItem() ? translate('out_of_stock') : translate('add_to_cart')}
                                        width={'270px'}
                                        disabled={loading || checkoutLoading || StoreCheckout.CheckOutofStockSelectedItem()}
                                        onClick={onCheckout}
                                        isloading={loading.toString()}
                                    />
                                    {(StoreCheckout.GetUuid() && cartHistory) || storeElement.dynamicWidth < 600 ? <></> : <MoneyBack />}
                                </Stack>
                                {StoreCheckout.GetUuid() && cartHistory && (
                                    <DefaultButton
                                        titleText={translate('checkout')}
                                        width={'270px'}
                                        backgroundcolor={MainTemplate.palette.primary.main}
                                        onClick={() => onCheckout(false)}
                                        isloading={checkoutLoading.toString()}
                                        disabled={checkoutLoading || loading}
                                    />
                                )}
                            </Stack>
                            {(StoreCheckout.GetUuid() && cartHistory) || storeElement.dynamicWidth < 600 ? (
                                <Stack>
                                    <MoneyBack />
                                </Stack>
                            ) : (
                                <></>
                            )}
                        </div>
                    </Stack>
                    <div style={{ height: '0px' }} ref={refElement} />
                </>
            ) : (
                <>
                    <Stack>
                        <Skeleton variant="rounded" width={'100%'} height={'360px'} />
                        <Stack mt={'20px'} display="flex" flexDirection="row" justifyContent="space-between">
                            <Skeleton variant="rounded" width={'18%'} height={'60px'} />
                            <Skeleton variant="rounded" width={'18%'} height={'60px'} />
                            <Skeleton variant="rounded" width={'18%'} height={'60px'} />
                            <Skeleton variant="rounded" width={'18%'} height={'60px'} />
                            <Skeleton variant="rounded" width={'18%'} height={'60px'} />
                        </Stack>
                    </Stack>
                    <Stack>
                        <Box mt={storeElement.dynamicWidth < 1180 && '20px'}>
                            <Skeleton variant="rounded" width={'100%'} height={'36px'} />

                            <Skeleton sx={{ marginTop: '20px' }} variant="rounded" width={'70%'} height={'20px'} />
                            <Skeleton sx={{ margin: '10px 0 0 20px' }} variant="rounded" width={'50%'} height={'15px'} />
                            <Skeleton sx={{ margin: '10px 0 0 20px' }} variant="rounded" width={'50%'} height={'15px'} />
                            <Skeleton sx={{ margin: '10px 0 0 20px' }} variant="rounded" width={'50%'} height={'15px'} />

                            <Box mt="40px">
                                <Skeleton sx={{ marginTop: '30px' }} variant="rounded" width={'60%'} height={'16px'} />
                                <Skeleton sx={{ marginTop: '30px' }} variant="rounded" width={'30%'} height={'18px'} />

                                <Stack mt={'20px'} display="flex" flexDirection="row" justifyContent="space-between">
                                    <Skeleton variant="rounded" width={'30%'} height={'48px'} />
                                    <Skeleton variant="rounded" width={'30%'} height={'48px'} />
                                    <Skeleton variant="rounded" width={'30%'} height={'48px'} />
                                </Stack>
                            </Box>

                            <Box mt="40px">
                                <Skeleton sx={{ marginTop: '30px' }} variant="rounded" width={'30%'} height={'18px'} />

                                <Stack mt={'20px'} display="flex" justifyContent="space-between">
                                    <Skeleton variant="rounded" width={'100%'} height={'70px'} />
                                    <Skeleton sx={{ marginTop: '16px' }} variant="rounded" width={'100%'} height={'70px'} />
                                </Stack>
                            </Box>

                            <Stack mt={'40px'} display="flex" flexDirection="row" justifyContent="space-between">
                                <Skeleton variant="rounded" width={'30%'} height={'24px'} />
                                <Skeleton variant="rounded" width={'30%'} height={'24px'} />
                            </Stack>
                            <Stack mt={'40px'} display="flex" flexDirection="row">
                                <Skeleton variant="rounded" width={'40%'} height={'48px'} />
                            </Stack>
                        </Box>
                    </Stack>
                </>
            )}
        </ProductsStyled>
    )
})

const ProductsStyled = styled(Container)(({ theme, isJapan }) => ({
    padding: '20px',
    display: 'flex',
    flexFlow: 'column',
    width: '100%',

    [theme.breakpoints.up('sm')]: {
        display: 'flex',
        flexFlow: 'column',
        width: '80%',
        paddingTop: '80px'
    },

    [theme.breakpoints.up('lg')]: {
        display: 'grid',
        gridTemplateColumns: 'calc(50% - 30px) calc(50% - 30px)',
        width: '100%',
        justifyContent: 'space-between'
    },

    [theme.breakpoints.up('xl')]: {
        paddingLeft: 0,
        paddingRight: 0
    },

    '.list-from-api': {
        marginTop: '16px',
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '24px',
        letterSpacing: '-0.1px',
        color: '#0b3b61',

        p: {
            '&:not(:first-of-type)': {
                margin: 0
            },
            '&:first-of-type': {
                marginBottom: 0
            }
        },

        h2: {
            fontSize: isJapan ? '36px' : 'original' // Adjust the font size based on isJapan
        }
    }
}))

export default ProductDetailSelection
